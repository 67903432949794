export var defaultDataPoints = [
    "name",
    "email",
    "address",
    "phone_number",
    "customer_id",
    "purchase_number",
    "pseudonym",
    "other",
];
export var recipientsList = [
    "categories_of_recipients_list.1",
    "categories_of_recipients_list.2",
    "categories_of_recipients_list.3",
    "categories_of_recipients_list.4",
    "categories_of_recipients_list.5",
    "categories_of_recipients_list.6",
    "categories_of_recipients_list.7",
    "categories_of_recipients_list.8",
    "categories_of_recipients_list.9",
    "categories_of_recipients_list.10",
    "categories_of_recipients_list.11",
    "categories_of_recipients_list.12",
    "categories_of_recipients_list.13",
];
export var shareDisclosedData = [
    {
        dataToSell: "categories_of_personal_info_list.1",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.2",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.3",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.4",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.5",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.6",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.7",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.8",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.9",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.10",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.11",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.12",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.13",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.14",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.15",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.16",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.17",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.18",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.19",
        sellOptions: recipientsList,
    },
    {
        dataToSell: "categories_of_personal_info_list.20",
        sellOptions: recipientsList,
    },
];
