var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import api from "services/api/base";
import { fileToFileObject } from "helpers";
export function getCases(businessId, caseType, active, orderBy, page, perPage, desc, searchTerm) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/v1/cases", {
                        params: {
                            case_type: caseType,
                            business_id: businessId,
                            order_by: orderBy,
                            page: page,
                            per_page: perPage,
                            desc: desc,
                            search_term: searchTerm,
                            active: active,
                        },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res === null || res === void 0 ? void 0 : res.data];
            }
        });
    });
}
export function getCase(caseUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/v1/case", {
                        params: { case_uuid: caseUuid },
                        show404OnError: true,
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    });
}
export function createTestCase(businessId, caseType, article) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/v1/case/test", { case_type: caseType, article: article }, {
                        params: { business_id: businessId },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    });
}
export function uploadFileToCase(caseUuid, files, type_) {
    return __awaiter(this, void 0, void 0, function () {
        var fileObjects, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(files.map(function (file) { return fileToFileObject(file); }))];
                case 1:
                    fileObjects = _a.sent();
                    return [4 /*yield*/, api.post("/v1/case/files", fileObjects, {
                            params: { case_uuid: caseUuid, type_: type_ },
                        })];
                case 2:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function removeFileFromCase(caseUuid, fileUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.delete("/v1/case/file", {
                        params: { case_uuid: caseUuid, file_uuid: fileUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function downloadUploadedCaseFile(caseUuid, fileUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/v1/case/file", {
                        params: { case_uuid: caseUuid, file_uuid: fileUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    });
}
export function updateCaseSender(caseUuid, replyData) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/authority/case/change-sender", replyData, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function updateCaseSenderWithContactUuid(caseUuid, contactUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/authority/case/change-sender", null, {
                        params: { case_uuid: caseUuid, contact_uuid: contactUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function closeCase(caseUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/close", null, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function reopenCase(caseUuid) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/reopen", null, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function closeCheck(caseUuid, data) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/close-check", data, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function reopenCheck(caseUuid, check_name) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/open-check", { check_name: check_name }, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function createInquiries(caseUuid, data) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/v1/case/inquiries", data, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function declineQuickCase(caseUuid, reason) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/quick-decline", reason, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function declineCase(caseUuid, data) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/decline", data, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function openInquiries(caseUuid, businessId) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/v1/case/inquiries/open", {
                        params: { case_uuid: caseUuid, business_id: businessId },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    });
}
export function answerInquiries(caseUuid, replyData) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/inquiries", replyData, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function extendDeadline(caseUuid, replyData) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/extend/deadline", replyData, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function finalizeCase(caseUuid, replyData) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/case/finalize", replyData, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function internalComment(caseUuid, data) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/v1/case/internal-comment", data, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function comment(caseUuid, data) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/dsr/comment", data, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function closeAsProcessor(caseUuid, contactId) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/v1/dsr/close-as-processor", { contact_id: contactId }, {
                        params: { case_uuid: caseUuid },
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
