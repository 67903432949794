var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { T } from "@tolgee/react";
import { useDebounce } from "helpers/hooks";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import TableSearch from "components/Input/TextField/Custom/SearchField";
import EnhancedTableHead from "components/DataDisplay/Table/TableHead";
import { getComparator, stableSort } from "components/DataDisplay/Table/helpers";
export default function EnhancedTable(props) {
    var _a, _b;
    var config = props.config;
    var values = config.values, keys = config.keys, settings = config.settings;
    var _c = useState([]), filteredValues = _c[0], setFilteredValues = _c[1];
    var navigate = useNavigate();
    var _d = useState("asc"), order = _d[0], setOrder = _d[1]; // TODO check if you can do a customHook with the 5 state below, also less useState means more performance.
    var _e = useState(""), orderBy = _e[0], setOrderBy = _e[1];
    var _f = useState(0), page = _f[0], setPage = _f[1];
    var _g = useState(10), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = useState(""), search = _h[0], setSearch = _h[1];
    var debouncedValue = useDebounce(search, 500);
    var _j = useState(false), open = _j[0], setOpen = _j[1]; // modal state
    var _k = useState(_jsx(_Fragment, {})), modalChildren = _k[0], setModalChildren = _k[1]; // modal state
    useEffect(function () {
        setFilteredValues(values);
    }, [values]);
    useEffect(function () {
        if (debouncedValue && debouncedValue !== "") {
            setFilteredValues(values.filter(function (value) {
                return Object.values(value).some(function (val) {
                    return val.toLowerCase().includes(debouncedValue.toLowerCase());
                });
            }));
        }
        else if (debouncedValue === "") {
            setFilteredValues(values);
        }
    }, [values, debouncedValue, setFilteredValues]);
    var handleRequestSort = function (event, property) {
        var isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    var handleClick = function (id) {
        var _a;
        var actionCell = (_a = settings === null || settings === void 0 ? void 0 : settings.onClickEvents) === null || _a === void 0 ? void 0 : _a.find(function (act) { return act.id === id; });
        if ((settings === null || settings === void 0 ? void 0 : settings.onClickType) === "REDIRECT") {
            navigate(actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl);
        }
        else if ((settings === null || settings === void 0 ? void 0 : settings.onClickType) === "MODAL") {
            setModalChildren(actionCell === null || actionCell === void 0 ? void 0 : actionCell.modalElement);
            setOpen(true);
        }
        else if ((settings === null || settings === void 0 ? void 0 : settings.onClickType) === "REDIRECT_FLASK") {
            window.location.assign(actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl);
        }
    };
    var sortList = function () {
        return settings.shouldSort
            ? stableSort(filteredValues, getComparator(order, orderBy))
            : filteredValues;
    };
    var emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredValues.length - page * rowsPerPage);
    emptyRows -= filteredValues.length === 0 && 1;
    var columnSize = ((_a = settings.action) === null || _a === void 0 ? void 0 : _a.headTitle) ? keys.length : keys.length - 1;
    return (_jsxs("div", { className: "w-full mb-5", children: [!(settings === null || settings === void 0 ? void 0 : settings.hideSearch) && _jsx(TableSearch, { searchState: [search, setSearch] }), _jsxs(Paper, { className: "w-full -mb-4", children: [_jsx(TableContainer, { children: _jsxs(Table, { style: { minWidth: "750px", minHeight: !(settings === null || settings === void 0 ? void 0 : settings.hidePagination) && "360px" }, "aria-labelledby": "tableTitle", "aria-label": "enhanced table", children: [_jsx(EnhancedTableHead, { actionTitle: (_b = settings.action) === null || _b === void 0 ? void 0 : _b.headTitle, keys: keys, shouldSort: settings === null || settings === void 0 ? void 0 : settings.shouldSort, order: order, orderBy: orderBy, onRequestSort: handleRequestSort }), _jsxs(TableBody, { children: [sortList()
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(function (row) {
                                            var _a, _b, _c, _d;
                                            var rowValues = Object.entries(row);
                                            var actionCell = (_c = (_b = (_a = config.settings) === null || _a === void 0 ? void 0 : _a.action) === null || _b === void 0 ? void 0 : _b.actionElements) === null || _c === void 0 ? void 0 : _c.find(function (act) { return act.id === row.id; }).action;
                                            return (_jsxs(TableRow, { hover: !!(settings === null || settings === void 0 ? void 0 : settings.onClickType), tabIndex: 0, className: clsx({
                                                    "cursor-pointer": settings === null || settings === void 0 ? void 0 : settings.onClickType,
                                                }), style: { height: "60px" }, children: [rowValues.map(function (_a, index) {
                                                        var _b;
                                                        var key = _a[0], value = _a[1];
                                                        return !((_b = settings === null || settings === void 0 ? void 0 : settings.ignoreFields) === null || _b === void 0 ? void 0 : _b.includes(key)) && (_jsx(TableCell, { onClick: function () { return handleClick(row.id); }, children: value }, index));
                                                    }), actionCell && (_jsx(TableCell, { align: "right", padding: "none", style: { width: (_d = settings === null || settings === void 0 ? void 0 : settings.action) === null || _d === void 0 ? void 0 : _d.actionColumnWidth }, children: actionCell }))] }, row.id));
                                        }), filteredValues.length === 0 && columnSize !== -1 && (_jsxs(TableRow, { hover: true, style: { height: "60px" }, children: [_jsx(TableCell, { children: _jsx(T, { keyName: "table.empty_message", ns: "generic" }) }), __spreadArray([], Array(columnSize), true).map(function (_, index) { return (_jsx(TableCell, {}, index)); })] })), !(settings === null || settings === void 0 ? void 0 : settings.hideEmptyRows) && emptyRows > 0 && (_jsx(TableRow, { style: { height: 60 * emptyRows }, children: _jsx(TableCell, { colSpan: 6 }) }))] })] }) }), !(settings === null || settings === void 0 ? void 0 : settings.hidePagination) && (_jsx(TablePagination, { style: { backgroundColor: "#d9d9d9" }, rowsPerPageOptions: [10, 25, 50], component: "div", count: filteredValues.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))] }), settings.onClickType === "MODAL" && (_jsx(BaseModal, { modalState: [open, setOpen], children: modalChildren }))] }));
}
