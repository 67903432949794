import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import clsx from "clsx";
import { Tooltip } from "@mui/material";
import { REACT_BASE_ROUTE } from "const/env";
import tolgee from "services/translation";
export default function NavItem(props) {
    var i18n = props.i18n, emoji = props.emoji, url = props.url, _a = props.notificationCount, notificationCount = _a === void 0 ? 0 : _a, todo = props.todo, icon = props.icon, type = props.type;
    return (_jsx(NavLink, { to: type === "REACT" ? url.replace(REACT_BASE_ROUTE, "") : url, className: clsx({ "text-prighterblue": icon, "text-gray-500": !icon }), children: function (_a) {
            var isActive = _a.isActive;
            return (_jsxs("div", { className: clsx("navbar-item", {
                    "bg-gray-200 font-bold": isActive,
                }), children: [icon ? (_jsx(FontAwesomeIcon, { icon: icon, color: "gray", fixedWidth: true })) : (_jsx(_Fragment, { children: emoji || _jsx("div", { className: "w-[17.5px]" }) })), _jsx("div", { children: _jsx(T, { keyName: i18n.key, ns: i18n.ns, params: i18n.params }) }), todo && (_jsx("span", { children: _jsx("span", { className: "items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 rounded-full bg-danger-400", children: _jsx(Tooltip, { title: tolgee.t({ key: "".concat(i18n.key, ".todo_tooltip") }), placement: "top-start", children: _jsx(FontAwesomeIcon, { icon: "exclamation-triangle", color: "navigation", fixedWidth: true }) }) }) })), notificationCount > 0 && (_jsx("span", { children: _jsx("span", { className: "items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 bg-orange-400 rounded-full", children: notificationCount }) }))] }));
        } }));
}
