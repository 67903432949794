var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import ExtendedSelectField from "components/Input/ExtendedSelectField";
import { useAppSelector } from "store";
import { getCountryAsOptions } from "helpers";
var businessSizeValues = ["GROWTH", "SMALL", "MEDIUM", "LARGE"];
var defaultValues = {
    street: "",
    postal: "",
    city: "",
    country_iso: "",
    role: "",
    name: "",
    email: "",
    position: "",
    password: "",
    company: "",
    authorised_to_sign: "",
    size: "",
};
export default function ManualOnboarding() {
    var _this = this;
    var _a = useState([]), countryOptions = _a[0], setCountryOptions = _a[1];
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    useEffect(function () {
        if (countries) {
            var options = getCountryAsOptions(countries);
            setCountryOptions(options);
        }
    }, [countries]);
    var dispatch = useDispatch();
    useEffect(function () {
        document.title = tolgee.t({ key: "quick_onboard", ns: "onboarding" });
    }, []);
    var _b = useForm({
        defaultValues: defaultValues,
    }), register = _b.register, handleSubmit = _b.handleSubmit, reset = _b.reset, watch = _b.watch, setValue = _b.setValue, _c = _b.formState, isDirty = _c.isDirty, isValid = _c.isValid;
    var companySize = watch("size");
    var countryISO = watch("country_iso");
    var emailReg = register("email");
    var nameReg = register("name");
    var roleReg = register("role");
    var companyReg = register("company");
    var authReg = register("authorised_to_sign");
    var streetReg = register("street");
    var cityReg = register("city");
    var codeReg = register("postal");
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var newBusiness, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newBusiness = {
                        user: {
                            name: data.name,
                            email: data.email,
                            position: data.role,
                            password: "",
                        },
                        address: {
                            street: data.street,
                            postal: data.postal,
                            city: data.city,
                            country_iso: countryISO,
                        },
                        company_name: data.company,
                        authorised_to_sign: data.authorised_to_sign,
                        size: companySize === null || companySize === void 0 ? void 0 : companySize.toUpperCase(),
                    };
                    return [4 /*yield*/, api.business.createBusiness(newBusiness)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({ message: tolgee.t({ key: "success", ns: "onboarding" }), type: "SUCCESS" }));
                        reset();
                        setValue("country_iso", "");
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "onboarding", children: _jsxs("div", { className: "box-outerlayout pb-4 pt-10 space-y-4", children: [_jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "email" }), required: true, onChange: emailReg.onChange, onBlur: emailReg.onBlur, name: emailReg.name, ref: emailReg.ref, "data-testid": "email" }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "name" }), required: true, onChange: nameReg.onChange, onBlur: nameReg.onBlur, name: nameReg.name, ref: nameReg.ref, "data-testid": "name" }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "role" }), required: true, onChange: roleReg.onChange, onBlur: roleReg.onBlur, name: roleReg.name, ref: roleReg.ref, "data-testid": "role" }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "company" }), required: true, onChange: companyReg.onChange, onBlur: companyReg.onBlur, name: companyReg.name, ref: companyReg.ref, "data-testid": "company" }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "authorise_sign" }), required: true, onChange: authReg.onChange, onBlur: authReg.onBlur, name: authReg.name, ref: authReg.ref }), _jsx(TextField, { select: true, className: "w-full", id: "select", required: true, value: companySize, onChange: function (event) {
                        var _a;
                        setValue("size", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                    }, label: tolgee.t({ key: "size", ns: "onboarding" }), children: businessSizeValues.map(function (i) { return (_jsx(MenuItem, { value: i, children: _jsx(T, { ns: "onboarding", keyName: i }) }, i)); }) }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "street" }), required: true, onChange: streetReg.onChange, onBlur: streetReg.onBlur, name: streetReg.name, ref: streetReg.ref }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "city" }), required: true, onChange: cityReg.onChange, onBlur: cityReg.onBlur, name: cityReg.name, ref: cityReg.ref }), _jsx(TextField, { label: _jsx(T, { ns: "onboarding", keyName: "code" }), required: true, onChange: codeReg.onChange, onBlur: codeReg.onBlur, name: codeReg.name, ref: codeReg.ref }), _jsx("div", { children: _jsx(ExtendedSelectField, { state: [countryISO, function (text) { return setValue("country_iso", text); }], options: countryOptions, className: "min-w-[12rem]", label: tolgee.t({ key: "country", ns: "generic" }), required: true }, countryISO) }), _jsx("div", { className: "w-full flex justify-end", children: _jsx(Button, { "data-testid": "submit", onClick: handleSubmit(onSubmit), disabled: !isDirty && !isValid, children: _jsx(T, { ns: "generic", keyName: "submit" }) }) })] }) }));
}
