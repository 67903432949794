var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "@mui/material";
export default function DSInfoSkeleton() {
    return (_jsxs("div", { className: "mb-4 box-outerlayout space-y-4", children: [_jsx("div", { className: "pt-5", children: _jsx(Skeleton, { width: "30%" }) }), _jsx(Skeleton, { width: "60%" }), _jsx(Skeleton, { width: "17%" }), _jsx("div", { className: "mb-4", children: _jsx("div", { className: "grid gap-x-2 gap-y-2 border border-slate-200 rounded-lg px-3 py-3", children: __spreadArray([], Array(3), true).map(function (_) { return (_jsxs("div", { children: [_jsx(Skeleton, { width: "50%" }), _jsx(Skeleton, { width: "80%" })] }, _)); }) }) }), _jsx(Skeleton, { width: "24%" }), _jsx("div", { className: "mb-4", children: _jsx("div", { className: "grid gap-x-2 gap-y-2 border border-slate-200 rounded-lg px-3 py-3", children: __spreadArray([], Array(3), true).map(function (_) { return (_jsxs("div", { children: [_jsx(Skeleton, { width: "50%" }), _jsx(Skeleton, { width: "80%" })] }, _)); }) }) }), _jsx("h3", { className: "mb-1", children: _jsx(Skeleton, { width: "30%" }) }), _jsx(Skeleton, { variant: "rectangular", height: 80 })] }));
}
