export var DEV_ENV = REACT_DEV_ENV;
export var REACT_BASE_ROUTE = REACT_BASE_URL;
export var PRIGHTER_URL = REACT_FLASK_BASE_URL;
export var API_BASE_ROUTE = REACT_API_BASE_URL;
export var STRIPE_KEY = STRIPE_PUBLISHABLE_KEY;
export var TOLGEE_URL = "https://app.tolgee.io";
export var RELEASE = CI_COMMIT_SHORT_SHA;
export var SENTRY_DSN = SENTRY_DSN_MAIN_APP;
// Dev keys
export var TOLGEE_KEY = DEV_ENV && REACT_TOLGEE_API_KEY;
// Dev prod keys
export var IS_PROD_DEV = REACT_IS_PROD_DEV;
// i18n for production
export var TOLGEE_CDN = "https://cdn.tolg.ee/661e6d87a3dc571a0f679e4d66bf4a20";
export var CDN = "https://cdn.prighter.com";
export var PRIVACY_POLICY = "".concat(CDN, "/legal/Prighter-PrivacyPolicy.pdf");
export var LEGAL_INFO = "".concat(PRIGHTER_URL, "/legal");
