var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { T } from "@tolgee/react";
import EmailCasesDefault from "pages/Client/EmailCases/Components/EmailCasesDefault";
import EmailCasesDetails from "pages/Client/EmailCases/Components/EmailCasesDetails";
import EmailCasesList from "pages/Client/EmailCases/Components/EmailCasesList";
import { api } from "services";
export default function EmailCases() {
    var _this = this;
    var _a = useState([]), emails = _a[0], setEmails = _a[1];
    var businessId = useParams().businessId;
    var _b = useState(null), currentEmailCase = _b[0], setCurrentEmailCase = _b[1];
    var fetchEmailById = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.getCase(uuid)];
                case 1:
                    response = _a.sent();
                    setCurrentEmailCase(response);
                    return [2 /*return*/];
            }
        });
    }); };
    var _c = useMemo(function () {
        var currentIndex = emails.findIndex(function (email) { return email.uuid === (currentEmailCase === null || currentEmailCase === void 0 ? void 0 : currentEmailCase.uuid); });
        var previous = null;
        var next = null;
        if (currentIndex > 0) {
            previous = emails[currentIndex - 1];
        }
        if (currentIndex < emails.length - 1) {
            next = emails[currentIndex + 1];
        }
        return [previous, next];
    }, [emails, currentEmailCase]), previousEmail = _c[0], nextEmail = _c[1];
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var isActive, orderBy, page, per_page, desc, searchString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isActive = true;
                        orderBy = "uuid";
                        page = 1;
                        per_page = 15;
                        desc = false;
                        searchString = "";
                        return [4 /*yield*/, api.getCases(businessId, "DSR_EMAIL", isActive, orderBy, page, per_page, desc, searchString)];
                    case 1:
                        response = _a.sent();
                        setEmails(response.result);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [businessId]);
    return (_jsxs("div", { className: "flex flex-col 2xl:flex-row gap-12 h-full ", children: [_jsx("div", { className: "flex-1", children: currentEmailCase ? (_jsxs(_Fragment, { children: [_jsx(EmailCasesDetails, { currentEmailCase: currentEmailCase }), _jsxs("div", { className: "flex justify-between items-center mt-6", children: [_jsxs(Button, { variant: "text", disabled: !previousEmail, onClick: function () { return fetchEmailById(previousEmail.uuid); }, className: "font-semibold text-[#505C76] gap-3", children: [_jsx(FontAwesomeIcon, { icon: "chevron-right", className: "rotate-180" }), _jsx(T, { keyName: "previous", ns: "generic" })] }), _jsxs(Button, { variant: "text", disabled: !nextEmail, onClick: function () { return fetchEmailById(nextEmail.uuid); }, className: "font-semibold text-[#505C76] gap-3", children: [_jsx(FontAwesomeIcon, { icon: "chevron-right" }), _jsx(T, { keyName: "next", ns: "generic" })] })] })] })) : (_jsx(EmailCasesDefault, {})) }), _jsx("div", { className: "flex-1", children: _jsx(EmailCasesList, { emails: emails, fetchEmailById: fetchEmailById }) })] }));
}
