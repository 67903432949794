var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { BaseSelectField, FormFileField } from "components";
import { validateEmail } from "helpers/validate";
import tolgee from "services/translation";
export default function IdentificationForm(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var checkboxState = props.checkboxState;
    var _w = useState(false), sameEmail = _w[0], setSameEmail = _w[1];
    var _x = useState("signedPermission"), legalRepresentativeFile = _x[0], setLegalRepresentativeFile = _x[1];
    var residentOfCalifornia = checkboxState[0], setResidentOfCalifornia = checkboxState[1];
    var _y = useFormContext(), register = _y.register, watch = _y.watch, getValues = _y.getValues, setValue = _y.setValue, errors = _y.formState.errors;
    var acting_for = watch("actors.1.acting_for");
    var files = watch("files");
    var identificationEmail = watch("identifiable_info.email");
    var senderEmail = watch("actors.1.email");
    useEffect(function () {
        if (sameEmail) {
            setValue("identifiable_info.email", getValues("actors.1.email"));
        }
    }, [getValues, senderEmail, sameEmail, setValue]);
    var displayAsLegalRepresentative = watch("dsrs").findIndex(function (dsr) { return dsr.checked && ["DSR_CCPA_OPTOUT", "DSR_CCPA_LIMIT"].includes(dsr.type); }) === -1;
    register("actors.1.acting_for", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0 || "Please choose something."; },
        },
    });
    register("files", {
        validate: {
            required: function (e) { return acting_for === "MYSELF" || e.length > 0 || "Please add at least 1 file."; },
        },
    });
    register("identifiable_info.email", {
        validate: {
            required: function (e) { return validateEmail(e) || "Please enter a valid email."; },
        },
    });
    var handleUploadFiles = function (fileList) {
        var fileArray = Array.from(fileList);
        var ccpaFileArray = fileArray.map(function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(ccpaFileArray)
            .then(function (resolvedFileArray) {
            setValue("files", resolvedFileArray.map(function (f, ind) { return ({ name: fileArray[ind].name, content: f }); }));
        })
            .catch(function (error) {
            captureException(error);
        });
        return true;
    };
    var handleCheckboxChange = function (event) {
        setSameEmail(event.target.checked);
        if (event.target.checked) {
            setValue("identifiable_info.email", getValues("actors.1.email"));
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex space-x-2 mt-6", children: [_jsx(Checkbox, { checked: residentOfCalifornia, style: { padding: 0, margin: 0, marginRight: 2 }, onChange: function (event) { return setResidentOfCalifornia(event.target.checked); } }), _jsxs("h2", { children: [_jsx(T, { keyName: "i_am_resident", ns: "ccpa" }), "*"] })] }), _jsxs("h2", { children: [_jsx(T, { keyName: "requester", ns: "dsr" }), ":"] }), _jsxs("div", { className: "flex flex-col space-y-4 mb-8", children: [_jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_a = errors === null || errors === void 0 ? void 0 : errors.actors[1]) === null || _a === void 0 ? void 0 : _a.acting_for), required: true, state: [acting_for, function (val) { return setValue("actors.1.acting_for", val); }], label: tolgee.t({ key: "ds_info.acting_for", ns: "ccpa" }), options: __assign({ MYSELF: tolgee.t({ key: "ds_info.acting_for.myself", ns: "ccpa" }), AS_PARENT: tolgee.t({
                                key: "ds_info.acting_for.as_parent",
                                ns: "ccpa",
                            }) }, (displayAsLegalRepresentative && {
                            AS_LEGAL_REPRESENTATIVE: tolgee.t({
                                key: "ds_info.acting_for.as_legal_representative",
                                ns: "ccpa",
                            }),
                        })) }), !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_b = errors === null || errors === void 0 ? void 0 : errors.actors[1]) === null || _b === void 0 ? void 0 : _b.acting_for) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "error_choose_acting_for", ns: "ccpa" }) })), _jsx(TextField, __assign({ label: tolgee.t({ key: "email.for_contact", ns: "dsr" }) }, register("actors.1.email", {
                        validate: {
                            minLength: function (e) { return "".concat(e).length >= 4 || "Minimum length of your email should be 4."; },
                            validEmail: function (e) { return validateEmail(e) || "You need to add a valid email."; },
                        },
                    }), { error: !!((_d = (_c = errors.actors) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.email), helperText: (_g = (_f = (_e = errors.actors) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.email) === null || _g === void 0 ? void 0 : _g.message, required: true })), acting_for && acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx(TextField, __assign({ label: tolgee.t({ key: "name", ns: "generic" }) }, register("actors.1.name", {
                                validate: {
                                    minLength: function (e) {
                                        return "".concat(e).length >= 4 || "Minimum length of your name should be 4.";
                                    },
                                },
                            }), { error: !!((_j = (_h = errors.actors) === null || _h === void 0 ? void 0 : _h[1]) === null || _j === void 0 ? void 0 : _j.name), helperText: (_m = (_l = (_k = errors.actors) === null || _k === void 0 ? void 0 : _k[1]) === null || _l === void 0 ? void 0 : _l.name) === null || _m === void 0 ? void 0 : _m.message, required: true })), acting_for === "AS_LEGAL_REPRESENTATIVE" && (_jsx(BaseSelectField, { label: tolgee.t({ key: "ds_info.as_legal_representative.file_type", ns: "ccpa" }), options: { signedPermission: "Signed Permission", poa: "Power of Attorney" }, state: [legalRepresentativeFile, setLegalRepresentativeFile] })), _jsxs("div", { children: [_jsxs("span", { className: "font-bold", children: [acting_for === "AS_PARENT" && (_jsx(T, { keyName: "ds_info.file_upload.as_parent", ns: "ccpa" })), acting_for === "AS_LEGAL_REPRESENTATIVE" &&
                                                legalRepresentativeFile === "signedPermission" && (_jsx(T, { keyName: "ds_info.file_upload.as_legal_representative.signed_permission", ns: "ccpa" })), acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (_jsx(T, { keyName: "ds_info.file_upload.as_legal_representative.poa", ns: "ccpa" }))] }), _jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: files, onChange: handleUploadFiles, multiple: true, fullWidth: true, required: true }), acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (_jsx("span", { className: "text-gray-500", children: _jsx(T, { keyName: "ds_info.file_upload.as_legal_representative.poa.text", ns: "ccpa" }) })), !!(errors === null || errors === void 0 ? void 0 : errors.files) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "error.sender_upload_at_least_one_file", ns: "ccpa" }) }))] })] }))] }), _jsxs("h2", { className: "mt-8", children: [_jsx(T, { keyName: "data_subject", ns: "dsr" }), ":"] }), _jsxs("div", { className: "flex flex-col space-y-4 mb-8", children: [_jsx(TextField, __assign({ label: tolgee.t({ key: "name", ns: "generic" }) }, register("actors.0.name", {
                        validate: {
                            minLength: function (e) { return "".concat(e).length >= 4 || "Minimum length of your name should be 4."; },
                        },
                    }), { error: !!((_p = (_o = errors.actors) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.name), helperText: (_s = (_r = (_q = errors.actors) === null || _q === void 0 ? void 0 : _q[0]) === null || _r === void 0 ? void 0 : _r.name) === null || _s === void 0 ? void 0 : _s.message, required: true })), _jsxs("div", { className: "flex space-x-2 mt-6", children: [_jsx(Checkbox, { checked: sameEmail, style: { padding: 0, margin: 0, marginRight: 2 }, onChange: handleCheckboxChange }), _jsx("span", { children: _jsx(T, { keyName: "use_the_same_email", ns: "dsr" }) })] }), _jsx(TextField, { value: identificationEmail, onChange: function (e) {
                            setValue("identifiable_info.email", e.target.value);
                            if (e.target.value === identificationEmail) {
                                setSameEmail(true);
                            }
                            else {
                                setSameEmail(false);
                            }
                        }, label: tolgee.t({ key: "email", ns: "generic" }), error: !!((_t = errors.identifiable_info) === null || _t === void 0 ? void 0 : _t.email), helperText: (_v = (_u = errors.identifiable_info) === null || _u === void 0 ? void 0 : _u.email) === null || _v === void 0 ? void 0 : _v.message, required: true })] })] }));
}
