import { getUrl } from "helpers";
import { jurisdictionDict } from "const";
import { handlingStepsOrder, preCheckStepsOrder } from "const/dsr";
import { stringifyAddress } from "helpers/general";
export function getCaseSender(caseContacts) {
    return caseContacts.find(function (ca) { return ca.acting_as === "SENDER"; });
}
export function getCaseRecipient(caseContacts) {
    return caseContacts.find(function (ca) { return ca.acting_as === "RECIPIENT"; });
}
export function getCaseControllerProcessorDecision(caseChecks) {
    var _a, _b;
    return (_b = (_a = caseChecks.find(function (check) { return check.name === "CONTROLLER_PROCESSOR"; })) === null || _a === void 0 ? void 0 : _a.case_decisions[0]) === null || _b === void 0 ? void 0 : _b.decision.name;
}
export function beautifyDSRequestName(case_) {
    if (case_.type.startsWith("DSR_LEGACY")) {
        if (!Array.isArray(case_.rights_concerned) || case_.rights_concerned.length === 0) {
            return "General";
        }
        return case_.rights_concerned.join(", ");
    }
    var requestNameDict = {
        AUTHORITY_INBOX: "Authority",
        DSR_CCPA_DELETE: "Deletion",
        DSR_CCPA_ACCESS: "Access",
        DSR_CCPA_ACCESS_SHARED: "Shared",
        DSR_CCPA_CORRECT: "Correction",
        DSR_CCPA_OPTOUT: "Opt-out",
        DSR_CCPA_LIMIT: "Limit",
        DSR_FADP_DELETE: "Deletion",
        DSR_FADP_ACCESS: "Access",
        DSR_FADP_CORRECT: "Correction",
        DSR_FADP_AUTOMATED_DECISION: "Automated Decision",
        DSR_FADP_PROHIBIT_DATA_PROCESSING: "Prohibit Data Processing",
        DSR_LEGACY_EU: "EU GDPR",
        DSR_LEGACY_UK: "UK GDPR",
        DSR_LEGACY: null,
        DSR: null,
        DSR_EMAIL: null,
    };
    return case_.type in requestNameDict ? requestNameDict[case_.type] : "General";
}
export function beautifyCaseType(case_) {
    var requestNameDict = {
        AUTHORITY_INBOX: "Authority",
        DSR_CCPA_DELETE: "CCPA",
        DSR_CCPA_ACCESS: "CCPA",
        DSR_CCPA_ACCESS_SHARED: "CCPA",
        DSR_CCPA_CORRECT: "CCPA",
        DSR_CCPA_OPTOUT: "CCPA",
        DSR_CCPA_LIMIT: "CCPA",
        DSR_FADP_DELETE: "FADP",
        DSR_FADP_ACCESS: "FADP",
        DSR_FADP_CORRECT: "FADP",
        DSR_FADP_AUTOMATED_DECISION: "FADP",
        DSR_FADP_PROHIBIT_DATA_PROCESSING: "FADP",
        DSR_LEGACY_EU: "EU GDPR",
        DSR_LEGACY_UK: "UK GDPR",
        DSR_LEGACY: null,
        DSR: null,
        DSR_EMAIL: null,
    };
    return case_.type in requestNameDict ? requestNameDict[case_.type] : "DSR";
}
export var generateUrl = function (caseUuid, fileUuid) {
    var params = new URLSearchParams({
        file_uuid: fileUuid,
        case_uuid: caseUuid,
    });
    return getUrl("API", "/v1/case/file", params);
};
export var getDSRUrl = function (dsrType, publicId, DsrUuid) {
    if (dsrType.includes("CCPA") || dsrType.includes("FADP")) {
        return "/case/".concat(DsrUuid);
    }
    return getUrl("FLASK", "/dsrtool/case/".concat(DsrUuid));
};
export function getDSRRequestFlag(type) {
    var requestNameDict = {
        AUTHORITY_INBOX: null,
        DSR_CCPA_DELETE: "dsr_cal",
        DSR_CCPA_ACCESS: "dsr_cal",
        DSR_CCPA_ACCESS_SHARED: "dsr_cal",
        DSR_CCPA_CORRECT: "dsr_cal",
        DSR_CCPA_OPTOUT: "dsr_cal",
        DSR_CCPA_LIMIT: "dsr_cal",
        DSR_FADP_DELETE: "dsr_fadp",
        DSR_FADP_ACCESS: "dsr_fadp",
        DSR_FADP_CORRECT: "dsr_fadp",
        DSR_FADP_AUTOMATED_DECISION: "dsr_fadp",
        DSR_FADP_PROHIBIT_DATA_PROCESSING: "dsr_fadp",
        DSR_LEGACY_EU: "dsr_eu",
        DSR_LEGACY_UK: "dsr_uk",
        DSR_LEGACY: null,
        DSR: null,
        DSR_EMAIL: null,
    };
    return type in requestNameDict
        ? jurisdictionDict[requestNameDict[type]].jurisdiction_lock_icon_url
        : "";
}
export function getJurisdictionByType(type) {
    var requestNameDict = {
        AUTHORITY_INBOX: "Authority",
        DSR_CCPA_DELETE: "CCPA",
        DSR_CCPA_ACCESS: "CCPA",
        DSR_CCPA_ACCESS_SHARED: "CCPA",
        DSR_CCPA_CORRECT: "CCPA",
        DSR_CCPA_OPTOUT: "CCPA",
        DSR_CCPA_LIMIT: "CCPA",
        DSR_FADP_DELETE: "FADP",
        DSR_FADP_ACCESS: "FADP",
        DSR_FADP_CORRECT: "FADP",
        DSR_FADP_AUTOMATED_DECISION: "FADP",
        DSR_FADP_PROHIBIT_DATA_PROCESSING: "FADP",
        DSR_LEGACY_EU: "EU GDPR",
        DSR_LEGACY_UK: "UK GDPR",
        DSR_LEGACY: null,
        DSR: null,
        DSR_EMAIL: null,
    };
    return type in requestNameDict ? requestNameDict[type] : "DSR";
}
export function getStageChecks(caseChecks, step) {
    var filteredChecks = caseChecks.filter(function (check) { return check.stage_name === step; });
    var stepsOrder = step === "PRE_CHECK" ? preCheckStepsOrder : handlingStepsOrder;
    return stepsOrder
        .map(function (name) { return filteredChecks.find(function (item) { return item.name === name; }); })
        .filter(Boolean);
}
export function getCurrentCaseStage(data) {
    var _a, _b;
    return (_b = (_a = data.case_stages) === null || _a === void 0 ? void 0 : _a.find(function (i) { return i.finished_at === null; })) === null || _b === void 0 ? void 0 : _b.stage.name;
}
export function getOpenInquiries(inquiries) {
    return (inquiries === null || inquiries === void 0 ? void 0 : inquiries.filter(function (i) { return i.status === "OPEN"; })) || [];
}
export function getClosedInquiries(inquiries) {
    return (inquiries === null || inquiries === void 0 ? void 0 : inquiries.filter(function (i) { return i.status === "CLOSED"; })) || [];
}
export function getDataSubject(caseActors) {
    return caseActors === null || caseActors === void 0 ? void 0 : caseActors.find(function (i) { return i.acting_as === "DATA_SUBJECT"; });
}
export function getSender(caseActors) {
    return caseActors === null || caseActors === void 0 ? void 0 : caseActors.find(function (i) { return i.acting_as === "SENDER"; });
}
export var isCheckFinished = function (state) {
    var _a;
    var uiStage = state.ui.stage === "HANDLING" ? "HANDLING" : "PRE_CHECK";
    var checks = getStageChecks(state.case.case_checks, uiStage);
    return (!!((_a = checks === null || checks === void 0 ? void 0 : checks.find(function (i) { return i.name === state.ui.check; })) === null || _a === void 0 ? void 0 : _a.closed_at) || state.case.state === "CLOSED");
};
export var getLatestActiveCheck = function (checks) {
    var _a;
    return ((_a = checks.find(function (check) { return !check.closed_at; })) === null || _a === void 0 ? void 0 : _a.name) || checks[checks.length - 1].name;
};
export var generateContactData = function (contact) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var data = [
        {
            key: "name",
            value: (_a = contact === null || contact === void 0 ? void 0 : contact.contact) === null || _a === void 0 ? void 0 : _a.name,
        },
        {
            key: "email_address",
            value: (_d = (_c = (_b = contact === null || contact === void 0 ? void 0 : contact.contact) === null || _b === void 0 ? void 0 : _b.contact_points) === null || _c === void 0 ? void 0 : _c.find(function (i) { return i.type === "EMAIL"; })) === null || _d === void 0 ? void 0 : _d.value,
        },
        {
            key: "phone_number",
            value: (_g = (_f = (_e = contact === null || contact === void 0 ? void 0 : contact.contact) === null || _e === void 0 ? void 0 : _e.contact_points) === null || _f === void 0 ? void 0 : _f.find(function (i) { return i.type === "PHONE"; })) === null || _g === void 0 ? void 0 : _g.value,
        },
    ];
    (_j = (_h = contact === null || contact === void 0 ? void 0 : contact.contact) === null || _h === void 0 ? void 0 : _h.addresses) === null || _j === void 0 ? void 0 : _j.forEach(function (i) {
        return data.push({
            key: "address",
            value: stringifyAddress(i),
        });
    });
    return data.filter(function (i) { return !!i.value; });
};
