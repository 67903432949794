var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { T, useTranslate } from "@tolgee/react";
import { BaseButton } from "components/Button";
import { SelectField } from "components/Input";
import { defaultDataPoints } from "const/ccpa/index";
var SelectedItemType;
(function (SelectedItemType) {
    SelectedItemType["required_inputs"] = "required_inputs";
    SelectedItemType["required_inputs_strict"] = "required_inputs_strict";
    SelectedItemType["additional_inputs"] = "additional_inputs";
})(SelectedItemType || (SelectedItemType = {}));
export default function DefaultDataPoints(_a) {
    var defaultInputs = _a.defaultInputs, strictInput = _a.strictInput, additionalInputs = _a.additionalInputs, saveDefaultPoints = _a.saveDefaultPoints;
    var t = useTranslate().t;
    var _b = useState([]), dataOptions = _b[0], setDataOptions = _b[1];
    var _c = useState([]), selectedOptions = _c[0], setSelectedOptions = _c[1];
    useEffect(function () {
        saveDefaultPoints(selectedOptions);
    }, [selectedOptions]);
    useEffect(function () {
        var formattedItems = defaultDataPoints.map(function (item) { return ({
            key: item,
            value: t("dsr.settings.ccpa.default_data.".concat(item)),
            selected: false,
        }); });
        var items = [];
        var _loop_1 = function (i) {
            var item = {
                id: i,
                key: formattedItems.find(function (formattedItem) { return formattedItem.key === defaultInputs[i]; }).key,
                value: formattedItems.find(function (formattedItem) { return formattedItem.key === defaultInputs[i]; }).value,
                type: SelectedItemType.required_inputs,
            };
            formattedItems.find(function (formattedItem) { return formattedItem.key === defaultInputs[i]; }).selected =
                true;
            items.push(item);
        };
        for (var i = 0; i < defaultInputs.length; i += 1) {
            _loop_1(i);
        }
        if (strictInput) {
            var _loop_2 = function (i) {
                var strictItem = {
                    id: items.length + 1,
                    key: formattedItems.find(function (formattedItem) { return formattedItem.key === strictInput[i]; }).key,
                    value: formattedItems.find(function (item) { return item.key === strictInput[i]; }).value,
                    type: SelectedItemType.required_inputs_strict,
                };
                formattedItems.find(function (item) { return item.key === strictInput[0]; }).selected = true;
                items.push(strictItem);
            };
            for (var i = 0; i < strictInput.length; i += 1) {
                _loop_2(i);
            }
        }
        if (additionalInputs) {
            var _loop_3 = function (i) {
                var additionalItem = {
                    id: items.length + 1,
                    key: formattedItems.find(function (formattedItem) { return formattedItem.key === additionalInputs[i]; })
                        .key,
                    value: formattedItems.find(function (item) { return item.key === additionalInputs[i]; }).value,
                    type: SelectedItemType.additional_inputs,
                };
                formattedItems.find(function (item) { return item.key === strictInput[0]; }).selected = true;
                items.push(additionalItem);
            };
            for (var i = 0; i < additionalInputs.length; i += 1) {
                _loop_3(i);
            }
        }
        setDataOptions(formattedItems);
        setSelectedOptions(items);
    }, [defaultInputs, strictInput, additionalInputs]);
    useEffect(function () { }, []);
    var handleSelect = function (updatedItem, updatedValue) {
        var updatedDataItems = selectedOptions.map(function (item) {
            return item.id === updatedItem.id
                ? __assign(__assign({}, item), { key: dataOptions.find(function (dataItem) { return dataItem.value === updatedValue; }).key, value: updatedValue }) : item;
        });
        setSelectedOptions(updatedDataItems);
        var updatedOptions = dataOptions.map(function (item) {
            // Update selected one
            if (item.value === updatedValue) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            // Update previously selected one
            if (item.value === updatedItem.value) {
                return __assign(__assign({}, item), { selected: false });
            }
            // Rest of items just return
            return item;
        });
        setDataOptions(updatedOptions);
    };
    var getFilteredItems = function (selectedItem) {
        return dataOptions.filter(function (item) { return !item.selected || item.value === selectedItem.value; });
    };
    var addAdditionalPoint = function () {
        var item = {
            id: selectedOptions.length + 1,
            key: "",
            value: "",
            type: SelectedItemType.additional_inputs,
        };
        var newDataItems = __spreadArray([], selectedOptions, true);
        newDataItems.push(item);
        setSelectedOptions(newDataItems);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsxs("div", { className: "box-dotted mb-4", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.default_data_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.default_data_description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), selectedOptions
                            .filter(function (item) { return item.type === SelectedItemType.required_inputs; })
                            .map(function (item, index) { return (_jsx("div", { className: "my-4", children: _jsx(SelectField, { label: item.value || t("dsr.settings.label_select"), value: item.value, options: getFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelect(item, value); } }) }, index)); }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.strict_input_description" }) }), selectedOptions
                            .filter(function (item) { return item.type === SelectedItemType.required_inputs_strict; })
                            .map(function (item, index) { return (_jsx("div", { className: "my-2", children: _jsx(SelectField, { label: item.value || t("dsr.settings.label_select"), value: item.value, options: getFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelect(item, value); } }) }, index)); })] }), _jsxs("div", { className: "box-dotted", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.additional_input_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.additional_input_description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), selectedOptions
                            .filter(function (item) { return item.type === SelectedItemType.additional_inputs; })
                            .map(function (item, index) { return (_jsx("div", { className: "my-2", children: _jsx(SelectField, { label: item.value || t("dsr.settings.label_select"), value: item.value, options: getFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelect(item, value); } }) }, index)); }), dataOptions.length > selectedOptions.length ? (_jsx("div", { className: "w-full flex align-center justify-center", children: _jsx(BaseButton, { className: "w-auto", variant: "outlined", onClick: addAdditionalPoint, children: _jsx(T, { keyName: "dsr.settings.ccpa.add_additional_data_point" }) }) })) : ("")] })] }) }));
}
