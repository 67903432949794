import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, Switch } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
export default function EnableDocumentUpload(_a) {
    var isEnabled = _a.isEnabled, saveRequiredDocuments = _a.saveRequiredDocuments;
    var _b = useState(false), enabled = _b[0], setEnabled = _b[1];
    useEffect(function () {
        saveRequiredDocuments(enabled);
    }, [enabled]);
    useEffect(function () {
        setEnabled(isEnabled);
    }, [isEnabled]);
    return (_jsxs("div", { className: "mt-4 mb-4 box-dotted", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.document_upload.title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.document_upload.description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: enabled, onChange: function (event) { return setEnabled(event.target.checked); } }), label: enabled ? _jsx(T, { keyName: "general.enabled" }) : _jsx(T, { keyName: "general.disabled" }) }) })] }));
}
