import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { T } from "@tolgee/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavItem from "components/Layout/Navbar/NavItem";
export default function NavMenu(props) {
    var _a;
    var navigate = useNavigate();
    var navMenu = props.navMenu, businesses = props.businesses, selectedBusiness = props.selectedBusiness, setSelectedBusiness = props.setSelectedBusiness;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function (id) {
        if (id !== undefined) {
            setSelectedBusiness(id);
            navigate("");
        }
        setAnchorEl(null);
    };
    return !(navMenu.business_id && navMenu.business_id !== selectedBusiness) ? (_jsxs("div", { children: [!navMenu.url && navMenu.i18n && (_jsxs("div", { className: "flex space-between w-full", children: [_jsx("div", { className: "mt-2 font-semibold text-prighterblue", children: _jsx(T, { keyName: navMenu.i18n.key, ns: navMenu.i18n.ns, params: navMenu.i18n.params }) }), businesses.length > 1 && navMenu.select_field && (_jsx(_Fragment, { children: _jsxs("div", { className: "flex items-center space-x-2", children: [_jsx(IconButton, { onClick: handleClick, children: _jsx(FontAwesomeIcon, { icon: "angle-down", color: "gray", size: "xs" }) }), _jsx(Menu, { anchorEl: anchorEl, open: open, onClose: function () { return handleClose(); }, anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }, transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    }, children: businesses.map(function (business) { return (_jsx(MenuItem, { selected: business.id === selectedBusiness, onClick: function () { return handleClose(business.id); }, children: business.name }, business.id)); }) })] }) }))] })), (_a = navMenu === null || navMenu === void 0 ? void 0 : navMenu.sub_menu) === null || _a === void 0 ? void 0 : _a.map(function (menu, ind) {
                var _a;
                return (_jsx("div", { className: "grid gap-1", children: ((_a = navMenu === null || navMenu === void 0 ? void 0 : navMenu.sub_menu) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(NavMenu, { navMenu: menu, businesses: businesses, selectedBusiness: selectedBusiness, setSelectedBusiness: setSelectedBusiness })) }, "sub-".concat(ind)));
            }), (navMenu === null || navMenu === void 0 ? void 0 : navMenu.i18n) && (navMenu === null || navMenu === void 0 ? void 0 : navMenu.url) && (_jsx(NavItem, { i18n: navMenu.i18n, url: navMenu.url, icon: navMenu === null || navMenu === void 0 ? void 0 : navMenu.icon, type: navMenu.type, emoji: navMenu === null || navMenu === void 0 ? void 0 : navMenu.emoji, notificationCount: navMenu.count, todo: navMenu.todo }))] })) : (_jsx(_Fragment, {}));
}
