import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function ConfirmInquiry(_a) {
    var handleSendInquiry = _a.handleSendInquiry, handleComplete = _a.handleComplete, confirmButtonText = _a.confirmButtonText, i18nSuffix = _a.i18nSuffix, declinePreview = _a.declinePreview, _b = _a.inquiry, inquiry = _b === void 0 ? true : _b;
    var _c = useCase(), state = _c[0], dispatch = _c[1];
    var caseData = state.case;
    var caseClosed = caseData.state === "CLOSED";
    var completeCheck = handleComplete || (function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check); });
    var isFinished = isCheckFinished(state);
    var inquiryLabel;
    var confirmLabel;
    if (i18nSuffix) {
        inquiryLabel = "send_inquiry_".concat(i18nSuffix);
        confirmLabel = "confirm_".concat(i18nSuffix);
    }
    return (_jsxs("div", { className: "flex flex-row justify-between", children: [_jsxs("div", { className: "space-x-2", children: [inquiry && (_jsx(Button, { onClick: handleSendInquiry, disabled: isFinished, children: tolgee.t({ key: inquiryLabel, ns: "dsr" }) })), !caseClosed && !isFinished && declinePreview && (_jsx(DeclineWithPreviewButton, { i18nSuffix: i18nSuffix, disabled: isFinished, declineType: declinePreview }))] }), _jsxs("div", { children: [_jsx(Button, { onClick: completeCheck, disabled: isFinished, sx: {
                            backgroundColor: "#4ad158",
                            "&:hover": { backgroundColor: "#4b904f" },
                        }, children: confirmButtonText || tolgee.t({ key: confirmLabel, ns: "dsr" }) }), isFinished && !caseClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: " !text-sm" }), sx: {
                            ml: 2,
                        }, children: tolgee.t({ key: "reopen", ns: "dsr" }) }))] })] }));
}
