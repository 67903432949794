var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, FormControl, FormControlLabel, MenuItem, Switch, TextField, } from "@mui/material";
import { T } from "@tolgee/react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { BaseIconButton, BaseModal } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
var defaultValues = [
    {
        question: "",
        question_i18n: { key: "", ns: "dsr" },
        upload_required: false,
        translate: true,
    },
];
export default function InquiryModal() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var inquiryQuestions = state.inquiry.questions;
    var appDispatch = useDispatch();
    var _b = useForm({
        defaultValues: {
            comment: "",
            questions: defaultValues,
        },
    }), handleSubmit = _b.handleSubmit, register = _b.register, reset = _b.reset, watch = _b.watch, control = _b.control, setValue = _b.setValue;
    var _c = useFieldArray({
        control: control,
        name: "questions",
    }), fields = _c.fields, append = _c.append, remove = _c.remove;
    var questionsData = watch("questions");
    var getFilteredData = function (data) {
        return data
            .filter(function (i) { return i.question_i18n.key || i.question; })
            .map(function (i) {
            return i.translate && inquiryQuestions.length > 0
                ? {
                    question_i18n: i.question_i18n,
                    upload_required: i.upload_required,
                }
                : { question: i.question, upload_required: i.upload_required };
        });
    };
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var filteredData, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    filteredData = getFilteredData(data.questions);
                    if (filteredData.length === 0) {
                        appDispatch(notify({ message: tolgee.t({ key: "enter_inquiry", ns: "dsr" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.createInquiries(state.case.uuid, filteredData)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({ message: tolgee.t({ key: "inquiry_successfull", ns: "dsr" }), type: "SUCCESS" }));
                        reset();
                        dispatch({ type: "CLOSE_INQUIRY_MODAL" });
                    }
                    else {
                        appDispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    fetchCaseData()(dispatch, state.case.uuid);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(BaseModal, { modalState: [state.inquiry.open, function () { return dispatch({ type: "CLOSE_INQUIRY_MODAL" }); }], className: "overflow-auto", closeEvent: reset, children: _jsxs("div", { className: "w-110 space-y-4", children: [_jsx("h3", { className: "text-center", children: _jsx(T, { ns: "dsr", keyName: "make_inquiry" }) }), _jsx(FormControl, { fullWidth: true, children: _jsxs("div", { className: "border border-black rounded-lg", children: [_jsx("ul", { className: "max-h-150 overflow-y-auto pl-0 mb-0 mt-0", children: fields === null || fields === void 0 ? void 0 : fields.map(function (item, index) { return (_jsxs("li", { className: "flex items-center space-x-4 p-4 border-b border-black", children: [_jsxs("div", { children: [inquiryQuestions.length > 0 && (_jsx(FormControlLabel, { control: _jsx(Switch, __assign({ checked: questionsData[index].translate }, register("questions.".concat(index, ".translate")), { inputProps: { "aria-label": "translation format switch" } })), label: tolgee.t({ key: "choose_question", ns: "dsr" }) })), questionsData[index].translate && inquiryQuestions.length > 0 ? (_jsx(TextField, { select: true, id: "select", className: "w-100 mt-4", value: questionsData[index].question_i18n.key, onChange: function (event) {
                                                        var _a;
                                                        setValue("questions.".concat(index, ".question_i18n.key"), (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                                                    }, label: "Question", children: inquiryQuestions.map(function (i) { return (_jsx(MenuItem, { value: i, children: _jsx(T, { ns: "dsr", keyName: "inquiry.".concat(i, "_title") }) })); }) })) : (_jsx(TextField, __assign({ className: "w-100", label: tolgee.t({ key: "question", ns: "dsr" }) }, register("questions.".concat(index, ".question"))))), questionsData[index].translate && questionsData[index].question_i18n.key && (_jsx("div", { className: "border-dotted border-gray-400 border-2 p-2 mt-2", children: _jsx(T, { keyName: "inquiry.".concat(questionsData[index].question_i18n.key), ns: "dsr" }) })), _jsx(FormControlLabel, { control: _jsx(Checkbox, __assign({}, register("questions.".concat(index, ".upload_required")))), label: tolgee.t({ key: "upload_required", ns: "dsr" }) })] }), index !== 0 && (_jsx(BaseIconButton, { onClick: function () { return remove(index); }, className: "w-5 h-5", size: "medium", children: _jsx(FontAwesomeIcon, { size: "xs", icon: "trash", color: "danger" }) }))] }, item.id)); }) }), _jsxs(Button, { fullWidth: true, type: "button", onClick: function () { return append(defaultValues); }, children: [_jsx(FontAwesomeIcon, { className: "mr-4", icon: "plus" }), _jsx(T, { ns: "dsr", keyName: "add_question" })] })] }) }), _jsx("div", { className: "flex justify-end pt-2", children: _jsx(Button, { onClick: handleSubmit(onSubmit), children: _jsx(T, { ns: "dsr", keyName: "send_inquiry" }) }) })] }) }));
}
