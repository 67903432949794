var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import Global from "components/DSRSettings/GlobalView";
import CCPA from "components/DSRSettings/CCPAView";
import { useAppSelector } from "store";
function TabPanel(props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { children: value === index && _jsx(Box, { sx: { p: 3 }, children: children }) })));
}
function a11yProps(index) {
    return {
        id: "tab-".concat(index),
        "aria-controls": "tabpanel-".concat(index),
    };
}
export default function DSRSettings() {
    var t = useTranslate().t;
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (_jsxs(Box, { sx: { width: "100%" }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: value, onChange: handleChange, "aria-label": "tabs", children: [_jsx(Tab, __assign({ label: t("dsr.settings.tabs.global") }, a11yProps(0))), managed_businesses[0].features.includes("ccpa") ? (_jsx(Tab, __assign({ label: t("dsr.settings.tabs.ccpa") }, a11yProps(1)))) : ("")] }) }), _jsx(TabPanel, { value: value, index: 0, children: _jsx(Global, {}) }), managed_businesses[0].features.includes("ccpa") ? (_jsx(TabPanel, { value: value, index: 1, children: _jsx(CCPA, {}) })) : ("")] }));
}
