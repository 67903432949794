import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { BaseButton } from "components";
import { isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function CorrectDelete() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var requestClosed = state.case.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    return (_jsxs(_Fragment, { children: [_jsx("span", { children: _jsx(T, { keyName: "description.correct_delete", ns: "ccpa" }) }), _jsxs("div", { className: "flex justify-end space-x-2", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({ key: "reopen", ns: "dsr" }) })), _jsxs("div", { className: "flex space-x-2", children: [_jsx(BaseButton, { disabled: isFinished, className: "w-full", color: "error", onClick: function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "DELETE"); }, children: tolgee.t({ key: "correction.correct_delete.delete", ns: "ccpa" }) }), _jsx(BaseButton, { disabled: isFinished, className: "w-full", color: "success", onClick: function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "CORRECT"); }, children: tolgee.t({ key: "correction.correct_delete.correct", ns: "ccpa" }) })] })] })] }));
}
