var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridConfig } from "helpers/dataGridConfig";
import api from "services/api";
import { getBeautyDateTime } from "helpers";
import { BaseButton, BaseIconButton, SearchField } from "components";
import { stringifyContactNameAndMail } from "helpers/contact";
import { useAppSelector } from "store";
import tolgee from "services/translation";
import { useDebounce } from "helpers/hooks";
export default function AuthorityList() {
    var _this = this;
    var _a = useState(""), search = _a[0], setSearch = _a[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState("created_at"), orderedBy = _c[0], setOrderedBy = _c[1];
    var _d = useState("desc"), newOrder = _d[0], setNewOrder = _d[1];
    var _e = useState(), data = _e[0], setData = _e[1];
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState(25), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var businessId = useParams().businessId;
    var authority_email_alias = useAppSelector(function (state) {
        var _a;
        return (_a = state.user.managed_businesses.find(function (business) { return business.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.authority_email_alias;
    }, shallowEqual);
    var navigate = useNavigate();
    var _h = useState(true), caseActive = _h[0], setCaseActive = _h[1];
    useEffect(function () {
        document.title = tolgee.t({ key: "authority_cases", ns: "cases" });
    }, []);
    function createTestCase() {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.createTestCase(businessId, "AUTHORITY_INBOX", null)];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            navigate("/case/".concat(res.uuid));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var fetchData = function (props) { return __awaiter(_this, void 0, void 0, function () {
        var order_by, page, per_page, order, search_term, desc, res, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    order_by = props.order_by, page = props.page, per_page = props.per_page, order = props.order, search_term = props.search_term;
                    desc = order === "desc";
                    return [4 /*yield*/, api.getCases(businessId, "AUTHORITY_INBOX", caseActive, order_by, page + 1, per_page, desc, search_term)];
                case 1:
                    res = _b.sent();
                    if (!res) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: rowsPerPage, count: res.count })), { onSortModelChange: function (sortVal) {
                            if ((sortVal === null || sortVal === void 0 ? void 0 : sortVal.length) === 0) {
                                return;
                            }
                            setOrderedBy(sortVal[0].field);
                            setNewOrder(sortVal[0].sort);
                        }, onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            setRowsPerPage(val.pageSize);
                        }, columns: [
                            {
                                headerName: tolgee.t({ key: "authority", ns: "cases" }),
                                flex: 1,
                                field: "ds_name",
                                valueGetter: function (_, params) {
                                    return stringifyContactNameAndMail(params.ds_name, params.ds_email, params.ds_salutation);
                                },
                            },
                            {
                                headerName: tolgee.t({ key: "title", ns: "cases" }),
                                flex: 1,
                                field: "title",
                            },
                            {
                                headerName: tolgee.t({ key: "company_name", ns: "cases" }),
                                flex: 1,
                                field: "company_name",
                            },
                            {
                                headerName: tolgee.t({ key: "state", ns: "cases" }),
                                flex: 1,
                                field: "state",
                            },
                            {
                                headerName: tolgee.t({ key: "created_at", ns: "generic" }),
                                flex: 1,
                                field: "created_at",
                                valueGetter: function (_, params) {
                                    return getBeautyDateTime(params.created_at);
                                },
                            },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }), onRowClick: function (params) {
                            navigate("/case/".concat(params.row.uuid));
                        } });
                    setData(tableData);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchData({
            order_by: orderedBy,
            page: currentPage,
            per_page: rowsPerPage,
            order: newOrder,
            search_term: debouncedSearchValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rowsPerPage, newOrder, orderedBy, debouncedSearchValue, caseActive]);
    return (_jsxs("div", { className: "box-outerlayout flex flex-col mx-auto max-w-7xl", children: [_jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx(BaseIconButton, { className: "border-full", onClick: function () {
                            return fetchData({
                                order_by: orderedBy,
                                page: currentPage,
                                per_page: rowsPerPage,
                                order: newOrder,
                                search_term: debouncedSearchValue,
                            });
                        }, children: _jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }) }), _jsx("h1", { children: _jsx(T, { keyName: "authority_cases", ns: "cases" }) })] }), _jsxs("div", { className: "flex justify-between items-center", children: [authority_email_alias ? (_jsxs("span", { children: [_jsx(T, { keyName: "incoming_cases_received_via", ns: "cases" }), _jsx("a", { href: "mailto:".concat(authority_email_alias), children: authority_email_alias })] })) : (_jsx("div", {})), _jsxs(ToggleButtonGroup, { value: caseActive, exclusive: true, onChange: function (_, newState) {
                            if (newState !== null && newState !== undefined) {
                                setCaseActive(newState);
                            }
                        }, children: [_jsx(ToggleButton, { value: true, children: _jsx(T, { keyName: "active", ns: "cases" }) }), _jsx(ToggleButton, { value: false, children: _jsx(T, { keyName: "closed", ns: "cases" }) })] })] }), data ? (_jsxs("div", { children: [_jsx(SearchField, { className: "w-full", searchState: [search, setSearch] }), _jsx(DataGrid, __assign({ sx: {
                            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                        } }, data, { loading: loading }))] })) : (!loading && _jsx("div", { children: tolgee.t({ key: "no_data", ns: "billing" }) })), businessId && (_jsx("div", { className: "self-end mt-8", children: _jsx(BaseButton, { onClick: function () { return createTestCase(); }, children: _jsx(T, { keyName: "create_test_case", ns: "cases" }) }) }))] }));
}
