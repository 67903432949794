var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { T, useTranslate } from "@tolgee/react";
import { CircularProgress } from "@mui/material";
import DefaultDataPoints from "components/DSRSettings/CCPA/DefaultDataPoints";
import SharingData from "components/DSRSettings/CCPA/SharingData";
import DisclosingData from "components/DSRSettings/CCPA/DisclosingData";
import EnableDocumentUpload from "components/DSRSettings/CCPA/EnableDocumentUpload";
import BetaProgram from "components/DSRSettings/CCPA/BetaProgram";
import { api } from "services";
import { notify } from "store/app";
export default function CCPAView() {
    var _this = this;
    var t = useTranslate("generic").t;
    var dispatch = useDispatch();
    var businessId = useParams().businessId;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(), backendData = _b[0], setBackendData = _b[1];
    var _c = useState([]), defaultDataPoints = _c[0], setDefaultDataPoints = _c[1];
    var _d = useState({
        isEnabled: false,
        items: [],
    }), sharingData = _d[0], setSharingData = _d[1];
    var _e = useState({
        isEnabled: false,
        items: [],
    }), disclosingData = _e[0], setDisclosingData = _e[1];
    var _f = useState(false), requiredDocumentsUpload = _f[0], setRequiredDocumentsUpload = _f[1];
    useEffect(function () {
        var payloadData = {
            required_inputs: defaultDataPoints
                .filter(function (dataItem) { return dataItem.type === "required_inputs"; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            required_inputs_strict: defaultDataPoints
                .filter(function (dataItem) { return dataItem.type === "required_inputs_strict"; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            additional_inputs: defaultDataPoints
                .filter(function (dataItem) { return dataItem.type === "additional_inputs"; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            categories_collected: {},
            categories_shared: prepareCategories(sharingData),
            categories_disclosed: prepareCategories(disclosingData),
            sell_share: sharingData.isEnabled,
            disclose: disclosingData.isEnabled,
            document_upload: requiredDocumentsUpload,
        };
        if (payloadData.required_inputs.length !== 0) {
            putDataToBackend(payloadData);
        }
    }, [defaultDataPoints, sharingData, disclosingData, requiredDocumentsUpload]);
    var prepareCategories = function (data) {
        var obj = {};
        for (var i = 0; i < (data === null || data === void 0 ? void 0 : data.items.length); i += 1) {
            if (data.items[i].sellOptions.length > 0) {
                obj[data.items[i].dataToSell.key] = data.items[i].sellTo.map(function (item) { return item.key; });
            }
        }
        return obj;
    };
    useEffect(function () {
        fetchData();
    }, [businessId]);
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, api.ccpa.getCCPASettings(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: t("smth_went_wrong"), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    setBackendData(res);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var putDataToBackend = function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.ccpa.updateCCPASettings(businessId, data)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "flex flex-col @container mx-auto max-w-7xl", children: loading ? (_jsx(CircularProgress, { size: "1.5rem" })) : (_jsxs("div", { children: [_jsxs("div", { className: "mt-4 mb-4 box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { keyName: "dsr.settings.ccpa.title" }) }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.description" }) }), _jsx("div", { className: "mb-4", children: _jsx(DefaultDataPoints, { defaultInputs: backendData.required_inputs, strictInput: backendData.required_inputs_strict, additionalInputs: backendData.additional_inputs, saveDefaultPoints: setDefaultDataPoints }) }), _jsx("div", { className: "mb-4", children: _jsx(SharingData, { isEnabled: backendData.sell_share, shareData: backendData.categories_shared, saveSharingData: setSharingData }) }), _jsx("div", { className: "mb-4", children: _jsx(DisclosingData, { isEnabled: backendData.disclose, discloseData: backendData.categories_disclosed, saveDisclosingData: setDisclosingData }) }), _jsx("div", { className: "mb-4", children: _jsx(EnableDocumentUpload, { isEnabled: backendData.document_upload, saveRequiredDocuments: setRequiredDocumentsUpload }) })] }), _jsx(BetaProgram, {})] })) }) }));
}
