var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { BaseSelectField } from "components";
import RightCheckbox from "pages/Public/Dsr/Fadp/Step1/RightCheckbox";
import tolgee from "services/translation";
export default function RightsForm() {
    var _a, _b, _c;
    var _d = useFormContext(), register = _d.register, watch = _d.watch, setValue = _d.setValue;
    var values = watch();
    return (_jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "rights_form.i_would_like_to", ns: "fadp" }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 mb-2" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.deletion_request_title", ns: "fadp" }), register: register("dsrs.0.checked"), checked: values.dsrs[0].checked, onCheck: function () { return setValue("dsrs.0.checked", !values.dsrs[0].checked); }, children: _jsx(TextField, __assign({}, register("dsrs.0.inquiries.0.answer"), { className: "w-full", label: tolgee.t({
                        key: "rights_form.request_refers_to_following_personal_info",
                        ns: "fadp",
                    }), rows: 4, multiline: true, InputLabelProps: {
                        shrink: true,
                    } })) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsxs(RightCheckbox, { title: tolgee.t({ key: "rights_form.access_request_title", ns: "fadp" }), register: register("dsrs.1.checked"), checked: values.dsrs[1].checked, onCheck: function () { return setValue("dsrs.1.checked", !values.dsrs[1].checked); }, children: [_jsx(BaseSelectField, { state: [
                            (_b = (_a = values.dsrs[1].inquiries[0]) === null || _a === void 0 ? void 0 : _a.answer_i18n) === null || _b === void 0 ? void 0 : _b.key,
                            function (val) {
                                setValue("dsrs.1.inquiries.0.answer_i18n.key", val);
                                setValue("dsrs.1.inquiries.0.answer_i18n.ns", "fadp");
                            },
                        ], options: {
                            "rights_form.categories_of_personal_information": tolgee.t({
                                key: "rights_form.categories_of_personal_information",
                                ns: "fadp",
                            }),
                            "rights_form.specific_pieces_of_personal_information": tolgee.t({
                                key: "rights_form.specific_pieces_of_personal_information",
                                ns: "fadp",
                            }),
                            "rights_form.categories_of_personal_information_sold_shared_or_disclosed": tolgee.t({
                                key: "rights_form.categories_of_personal_information_sold_shared_or_disclosed",
                                ns: "fadp",
                            }),
                        } }), ((_c = values.dsrs[1].inquiries[0].answer_i18n) === null || _c === void 0 ? void 0 : _c.key) ===
                        "rights_form.specific_pieces_of_personal_information" && (_jsx("div", { className: "mt-4", children: _jsx(TextField, __assign({}, register("dsrs.1.inquiries.1.answer"), { className: "w-full", label: tolgee.t({
                                key: "rights_form.my_request_concerns_following_information",
                                ns: "fadp",
                            }), rows: 4, multiline: true, InputLabelProps: {
                                shrink: true,
                            } })) }))] }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.change_request_title", ns: "fadp" }), register: register("dsrs.2.checked"), checked: values.dsrs[2].checked, onCheck: function () { return setValue("dsrs.2.checked", !values.dsrs[2].checked); }, children: _jsxs("div", { className: "mt-4 space-y-6", children: [_jsx(TextField, __assign({}, register("dsrs.2.inquiries.0.answer"), { className: "w-full", label: tolgee.t({
                                key: "rights_form.request_related_to_following_personal_information",
                                ns: "fadp",
                            }), rows: 4, multiline: true, InputLabelProps: {
                                shrink: true,
                            } })), _jsx(TextField, __assign({}, register("dsrs.2.inquiries.1.answer"), { className: "w-full", label: tolgee.t({
                                key: "rights_form.personal_information_is_wrong_or_incomplete_because",
                                ns: "fadp",
                            }), rows: 4, multiline: true, InputLabelProps: {
                                shrink: true,
                            } })), _jsx(TextField, __assign({}, register("dsrs.2.inquiries.2.answer"), { className: "w-full", label: tolgee.t({
                                key: "rights_form.amend_the_personal_information_as_follows",
                                ns: "fadp",
                            }), rows: 4, multiline: true, InputLabelProps: {
                                shrink: true,
                            } }))] }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.automated_decision", ns: "fadp" }), register: register("dsrs.3.checked"), checked: values.dsrs[3].checked, onCheck: function () { return setValue("dsrs.3.checked", !values.dsrs[3].checked); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.prohibit_data_processing", ns: "fadp" }), register: register("dsrs.4.checked"), checked: values.dsrs[4].checked, onCheck: function () { return setValue("dsrs.4.checked", !values.dsrs[4].checked); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] }));
}
