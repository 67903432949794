import { jsx as _jsx } from "react/jsx-runtime";
import DoINeedChinaRep from "pages/Client/Dashboard/DoINeedModals/DoINeedChinaRep";
import DoINeedGdprRep from "pages/Client/Dashboard/DoINeedModals/DoINeedGdprRep";
import DoINeedUkRep from "pages/Client/Dashboard/DoINeedModals/DoINeedUkRep";
import DoINeedNis from "pages/Client/Dashboard/DoINeedModals/DoINeedNis";
import DoINeedTurkeyRep from "pages/Client/Dashboard/DoINeedModals/DoINeedTurkeyRep";
import DoINeedSwissRep from "pages/Client/Dashboard/DoINeedModals/DoINeedSwissRep";
import breach from "assets/images/breach.png";
import dsr from "assets/images/dsr.png";
import { getUrl } from "helpers";
export var jurisdictionDict = {
    dsr_uk: {
        name_of_request: "UK related request",
        jurisdiction_lock_icon_url: getUrl("FLASK", "/static/img/prighter/ukrep/productlogo_ukgdprrep.png"),
        dsr_type: "dsr_uk",
        short_name: "UK",
    },
    dsr_cal: {
        name_of_request: "California related request",
        jurisdiction_lock_icon_url: getUrl("FLASK", "/static/img/prighter/california/california-3d-lock.png"),
        dsr_type: "dsr_cal",
        short_name: "California",
    },
    dsr_eu: {
        name_of_request: "EU related request",
        jurisdiction_lock_icon_url: getUrl("FLASK", "/static/img/prighter/gdprrep/productlogo_eugdprrep.png"),
        dsr_type: "dsr_eu",
        short_name: "EU",
    },
    dsr_fadp: {
        name_of_request: "Switzerland related request",
        jurisdiction_lock_icon_url: getUrl("FLASK", "/static/img/prighter/swiss-3d-lock.png"),
        dsr_type: "dsr_fadp",
        short_name: "CH",
    },
};
export var replyTypeDict = {
    TEXTFIELD: "Text Field",
    TEXTAREA: "Text Area",
    FILEUPLOAD: "File Upload",
    NO_REPLY: "No Reply",
};
export var getReplyType = function (key) {
    return key in replyTypeDict ? replyTypeDict[key] : "No Reply";
};
export var getReversedReplyType = function (key) {
    var dict = {
        "Text Field": "TEXTFIELD",
        "Text Area": "TEXTAREA",
        "File Upload": "FILEUPLOAD",
        "No Reply": "NO_REPLY",
    };
    return key in dict ? dict[key] : "NO_REPLY";
};
export var getGuideTypeLabel = function (key) {
    var dict = {
        AUTHORITY_INBOX: "Authority Inbox",
    };
    return key in dict ? dict[key] : "Guide";
};
export var productKeys = [
    "art27",
    "ukrep",
    "niseu",
    "nisuk",
    "turkeyrep",
    "chinarep",
    "swissrep",
];
export var productDict = {
    art27: {
        name: "PrighterGDPR-Rep",
        image: getUrl("FLASK", "/static/img/prighter/gdprrep/productlogo_eugdprrep.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/eu-rep"); },
        doINeedModal: _jsx(DoINeedGdprRep, {}),
    },
    ukrep: {
        name: "PrighterUK-Rep",
        image: getUrl("FLASK", "/static/img/prighter/ukrep/productlogo_ukgdprrep.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/uk-rep"); },
        doINeedModal: _jsx(DoINeedUkRep, {}),
    },
    niseu: {
        name: "Prighter NIS EU",
        image: getUrl("FLASK", "/static/img/prighter/niseu/product_niseu.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/nis"); },
        doINeedModal: _jsx(DoINeedNis, {}),
    },
    nisuk: {
        name: "Prighter NIS UK",
        image: getUrl("FLASK", "/static/img/prighter/nisuk/product_nisuk.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/nis?open=nisuk"); },
        doINeedModal: _jsx(DoINeedNis, {}),
    },
    turkeyrep: {
        name: "Prighter Turkey-DCR",
        image: getUrl("FLASK", "/static/img/prighter/turkeyrep/turkey-3d-lock.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/tr-rep"); },
        doINeedModal: _jsx(DoINeedTurkeyRep, {}),
    },
    chinarep: {
        name: "China-Rep",
        image: getUrl("FLASK", "/static/img/prighter/chinarep/china-3d-lock.png"),
        productUrl: function () { return ""; },
        doINeedModal: _jsx(DoINeedChinaRep, {}),
    },
    swissrep: {
        name: "Swiss-Rep",
        image: getUrl("FLASK", "/static/img/prighter/swiss-3d-lock.png"),
        productUrl: function (businessId) { return "/business/".concat(businessId, "/ch-rep"); },
        doINeedModal: _jsx(DoINeedSwissRep, {}),
    },
    prighterbreach: {
        name: "Prighter Breach",
        image: breach,
        productUrl: null,
        doINeedModal: null,
    },
    "dsr-archival": {
        name: "PrighterDSR Archival",
        image: dsr,
        productUrl: null,
        doINeedModal: null,
    },
};
export var allLang = [
    "bg",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "es",
    "et",
    "fi",
    "fr",
    "hu",
    "it",
    "ja",
    "it",
    "lv",
    "nl",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sl",
    "sv",
    "zh",
];
