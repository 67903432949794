var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tolgee from "services/translation";
import { BaseButton, BaseIconButton, BaseModal, SearchField } from "components";
import { notify } from "store/app";
import { api } from "services";
import { dataGridConfig } from "helpers/dataGridConfig";
import { useDebounce } from "helpers/hooks";
import UserSearch from "pages/Admin/Vouchers/Components/UserSearch";
export default function Vouchers() {
    var _this = this;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(""), search = _b[0], setSearch = _b[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _c = useState(), notValidAfter = _c[0], setNotValidAfter = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(), data = _e[0], setData = _e[1];
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState("public_id"), orderedBy = _g[0], setOrderedBy = _g[1];
    var _h = useState("desc"), newOrder = _h[0], setNewOrder = _h[1];
    var _j = useState(25), rowsPerPage = _j[0], setRowsPerPage = _j[1];
    var dispatch = useDispatch();
    useEffect(function () {
        document.title = tolgee.t({ key: "vouchers_title", ns: "vouchers" });
    }, []);
    var _k = useForm(), register = _k.register, handleSubmit = _k.handleSubmit, reset = _k.reset, setValue = _k.setValue, watch = _k.watch, _l = _k.formState, isDirty = _l.isDirty, errors = _l.errors;
    var durationValue = watch("duration");
    var user = watch("referral_user_uuid");
    var fetchData = function (props) { return __awaiter(_this, void 0, void 0, function () {
        var order_by, page, per_page, order, search_term, desc, res, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    order_by = props.order_by, page = props.page, per_page = props.per_page, order = props.order, search_term = props.search_term;
                    desc = order === "desc";
                    return [4 /*yield*/, api.vouchers.getVouchers({
                            order_by: order_by,
                            page: page + 1,
                            per_page: per_page,
                            desc: desc,
                            search_term: search_term,
                        })];
                case 1:
                    res = _b.sent();
                    if (!res) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: rowsPerPage, count: res.count })), { onSortModelChange: function (sortVal) {
                            if ((sortVal === null || sortVal === void 0 ? void 0 : sortVal.length) === 0) {
                                return;
                            }
                            setOrderedBy(sortVal[0].field);
                            setNewOrder(sortVal[0].sort);
                        }, onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            setRowsPerPage(val.pageSize);
                        }, columns: [
                            {
                                flex: 2,
                                field: "public_id",
                                headerName: "Voucher ID",
                            },
                            {
                                flex: 1,
                                field: "percentage_off",
                                headerName: "Percentage off",
                                renderCell: function (params) {
                                    return (_jsxs("div", { className: "flex flex-col -ml-2 p-2", children: [(params.row.percentage_off / 100).toFixed(2), " %"] }));
                                },
                            },
                            { flex: 1, field: "duration_in_months", headerName: "Duration in month" },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }) });
                    if (tableData) {
                        setData(tableData);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (fields) { return __awaiter(_this, void 0, void 0, function () {
        var voucherData, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    voucherData = __assign(__assign({}, fields), { duration: fields.duration || "FOREVER", duration_in_months: durationValue === "REPEATING" ? fields.duration_in_months : undefined, referral_share: user ? fields.referral_share : undefined, not_valid_after: notValidAfter ? notValidAfter.toISOString() : undefined });
                    return [4 /*yield*/, api.vouchers.createVoucher(voucherData)];
                case 1:
                    res = _a.sent();
                    if (res && (res === null || res === void 0 ? void 0 : res.detail[0].msg)) {
                        notify({ message: res === null || res === void 0 ? void 0 : res.detail[0].msg, type: "ERROR" });
                        return [2 /*return*/];
                    }
                    setOpen(false);
                    reset();
                    setNotValidAfter(null);
                    fetchData({
                        order_by: orderedBy,
                        page: currentPage,
                        per_page: rowsPerPage,
                        order: newOrder,
                        search_term: debouncedSearchValue,
                    });
                    dispatch(notify({ message: tolgee.t({ key: "voucher_created", ns: "vouchers" }), type: "SUCCESS" }));
                    return [2 /*return*/];
            }
        });
    }); };
    var checkDeadlineValue = function (val) {
        if (val === null || val === void 0 ? void 0 : val.isValid()) {
            setNotValidAfter(val);
        }
    };
    useEffect(function () {
        fetchData({
            order_by: orderedBy,
            page: currentPage,
            per_page: rowsPerPage,
            order: newOrder,
            search_term: debouncedSearchValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rowsPerPage, orderedBy, newOrder, debouncedSearchValue]);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsx(BaseModal, { modalState: [open, setOpen], closeEvent: function () {
                    setNotValidAfter(null);
                    reset();
                }, children: _jsxs("div", { className: "flex flex-col space-y-4 w-[600px]", children: [_jsx(TextField, __assign({ required: true }, register("public_id", {
                            validate: {
                                minLength: function (e) { return "".concat(e).length > 0; },
                            },
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.public_id), label: _jsx(T, { ns: "vouchers", keyName: "voucher_code" }), style: { backgroundColor: "white" } })), _jsxs(TextField, { required: true, id: "select", defaultValue: "FOREVER", value: durationValue, onChange: function (event) {
                                var _a;
                                setValue("duration", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                            }, select: true, style: { backgroundColor: "white" }, label: "Duration", children: [_jsx(MenuItem, { value: "REPEATING", children: _jsx(T, { ns: "vouchers", keyName: "repeating" }) }), _jsx(MenuItem, { value: "FOREVER", children: _jsx(T, { ns: "vouchers", keyName: "forever" }) })] }), durationValue === "REPEATING" && (_jsx(TextField, __assign({ required: true }, register("duration_in_months", {
                            validate: {
                                minLength: function (e) { return "".concat(e).length > 0; },
                            },
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.duration_in_months), style: { backgroundColor: "white" }, label: _jsx(T, { ns: "vouchers", keyName: "duration_month" }), type: "number", InputProps: { inputProps: { min: 0 } } }))), _jsx(TextField, __assign({ required: true }, register("percentage_off", {
                            min: 0,
                            max: 100,
                            validate: {
                                minLength: function (e) { return "".concat(e).length > 0; },
                            },
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.percentage_off), style: { backgroundColor: "white" }, label: _jsx(T, { ns: "vouchers", keyName: "percentage_off" }), type: "number", InputProps: { inputProps: { min: 0, max: 100 } } })), _jsx(UserSearch, { onChange: function (_, value) { return setValue("referral_user_uuid", value ? value.value : null); } }), user && (_jsx(TextField, __assign({ required: true }, register("referral_share", {
                            min: 0,
                            max: 100,
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.referral_share), style: { backgroundColor: "white" }, label: _jsx(T, { ns: "vouchers", keyName: "referral_share" }) }))), _jsx("div", { className: "flex", children: _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { disablePast: true, format: "DD/MM/YYYY", className: "w-full bg-white", label: _jsx(T, { ns: "vouchers", keyName: "not_valid_after" }), value: notValidAfter, onChange: function (newValue) { return checkDeadlineValue(newValue); } }) }) }), _jsx("div", { className: "w-fit mt-4 flex self-end", children: _jsx(BaseButton, { onClick: handleSubmit(onSubmit), disabled: !isDirty, children: _jsx(T, { ns: "generic", keyName: "create" }) }) })] }) }), _jsx("div", { className: "my-4 flex justify-end", children: _jsx(Button, { onClick: function () { return setOpen(true); }, children: _jsx(T, { ns: "vouchers", keyName: "create_voucher" }) }) }), _jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { ns: "vouchers", keyName: "all_vouchers" }) }), data ? (_jsxs("div", { className: "space-y-6", children: [_jsxs("div", { className: "flex space-x-4 items-center", children: [_jsx(BaseIconButton, { className: "border-full", onClick: function () {
                                            return fetchData({
                                                order_by: orderedBy,
                                                page: currentPage,
                                                per_page: rowsPerPage,
                                                order: newOrder,
                                                search_term: debouncedSearchValue,
                                            });
                                        }, children: _jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }) }), _jsx(SearchField, { className: "w-full", searchState: [search, setSearch] })] }), _jsx(DataGrid, __assign({}, data, { loading: loading }))] })) : (_jsx("div", { children: _jsx(T, { ns: "generic", keyName: "no_data" }) }))] })] }));
}
