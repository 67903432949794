import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Tooltip, Typography, ListItemText, } from "@mui/material";
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function SelectField(_a) {
    var options = _a.options, value = _a.value, onChange = _a.onChange, label = _a.label;
    var handleChange = function (event) {
        onChange(event.target.value);
    };
    var isValueLong = function (text) {
        return text.length > 40;
    };
    var getStyles = function () { return ({
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "200px",
    }); };
    return (_jsxs(FormControl, { sx: { width: "100%" }, children: [_jsx(InputLabel, { id: "select-field-label", children: label }), _jsx(Select, { labelId: "select-field-label", id: "select-field", value: value, onChange: handleChange, input: _jsx(OutlinedInput, { label: label }), MenuProps: MenuProps, renderValue: function (selected) {
                    return isValueLong(selected) ? (_jsx(Tooltip, { title: selected, children: _jsx(Typography, { style: getStyles(), children: selected }) })) : (_jsx(Typography, { children: selected }));
                }, children: options.map(function (option) {
                    return (_jsx(MenuItem, { value: option, children: _jsx(ListItemText, { primary: option }) }, option));
                }) })] }));
}
