import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { BaseSelectField } from "components";
import { buttonStyle, selectStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EducationalFormWhere(props) {
    var nextStep = props.nextStep;
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, errors = _a.formState.errors;
    var type = watch("type");
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsxs("h2", { className: "m-0 text-black font-fire text-[28px]", children: [_jsx(T, { keyName: "section", ns: "generic" }), " 1: ", _jsx(T, { keyName: "where", ns: "generic" })] }), _jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: _jsx(T, { keyName: "data_subject_requested_from_california", ns: "email_intake" }) })] }), _jsx(FormControl, { fullWidth: true, className: "flex flex-col gap-3", children: _jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.type), required: true, sx: selectStyle, state: [type, function (val) { return setValue("type", val); }], 
                    // label={tolgee.t({ key: "ds_info.acting_for", ns: "fadp" })}
                    options: {
                        DSR_CCPA: "Yes",
                        AUTHORITY: "No",
                    } }) }), _jsxs("div", { className: "flex items-center", children: [_jsx(Button, { type: "button", sx: buttonStyle, onClick: nextStep, disabled: type !== "DSR_CCPA", children: _jsx(T, { keyName: "next_section", ns: "generic" }) }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: _jsx(T, { keyName: "or_press_enter", ns: "generic" }) }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: _jsx(T, { keyName: "missing_information", ns: "generic" }) })] }), _jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "education", ns: "email_intake" }) }), _jsx("h3", { children: _jsx(T, { keyName: "what_is_legal_framework", ns: "email_intake" }) }), _jsxs("div", { className: "box", children: [_jsx("p", { children: _jsx(T, { keyName: "legal_framework", ns: "email_intake" }) }), _jsx("p", { children: _jsx(T, { keyName: "legal_framework_explained", ns: "email_intake" }) })] }), _jsx("p", { children: _jsx(T, { keyName: "different_legal_jurisdictions", ns: "email_intake" }) }), _jsx("h2", { children: _jsx(T, { keyName: "education", ns: "email_intake" }) })] })] }));
}
