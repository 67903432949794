var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { validateEmailDomainsSame } from "helpers/validate";
import { api } from "services";
import tolgee from "services/translation";
import { useAppDispatch, useAppSelector } from "store";
import { notify } from "store/app";
var channels = [
    "channel_admin",
    "channel_all",
    "channel_essential",
    "channel_billing",
    "channel_compliance",
    "channel_privacy",
    "channel_dsr_details",
];
var channelsMapping = {
    channel_admin: "ADMIN",
    channel_all: "ALL",
    channel_essential: "ALL_ESSENTIAL",
    channel_billing: "BILLING",
    channel_compliance: "COMPLIANCE_RELATED",
    channel_privacy: "PRIVACY_KNOWLEDGE",
    channel_dsr_details: "DSR_DETAILS",
};
export default function AddNotification(_a) {
    var _this = this;
    var userUuid = _a.userUuid, handleReload = _a.handleReload;
    var dispatch = useAppDispatch();
    var user = useAppSelector(function (state) { return state.user; });
    var strictDomain = user.config.email_channel_strict_domain;
    var _b = useForm({
        values: { channel: "", email: "" },
    }), register = _b.register, getValues = _b.getValues, handleSubmit = _b.handleSubmit, reset = _b.reset;
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var channelData, userData, success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (strictDomain && !validateEmailDomainsSame(data === null || data === void 0 ? void 0 : data.email, user.email)) {
                        dispatch(notify({
                            message: tolgee.t({ key: "description.strict_domain", ns: "notifications" }),
                            type: "ERROR",
                            duration: 10,
                        }));
                        return [2 /*return*/];
                    }
                    channelData = getValues().channel;
                    if (!((data === null || data === void 0 ? void 0 : data.email) && channelData)) return [3 /*break*/, 2];
                    userData = {
                        address: data === null || data === void 0 ? void 0 : data.email,
                        type: channelsMapping[channelData],
                    };
                    return [4 /*yield*/, api.notifications.addNotification(userUuid, userData)];
                case 1:
                    success = _a.sent();
                    if (success) {
                        dispatch(notify({
                            message: tolgee.t({ key: "notification_added", ns: "notifications" }),
                            type: "SUCCESS",
                        }));
                        reset();
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    handleReload();
                    return [3 /*break*/, 3];
                case 2:
                    dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { children: [_jsx("h3", { className: "mt-8 font-bold", children: _jsx(T, { ns: "notifications", keyName: "add_notification" }) }), _jsx(FormControl, { fullWidth: true, children: _jsxs("div", { className: "flex w-full", children: [_jsx("div", { className: "flex-1 mr-4", children: _jsx(TextField, __assign({ className: "w-full", id: "select", label: "Select Channel", select: true }, register("channel"), { children: channels.map(function (i) { return (_jsx(MenuItem, { value: i, children: _jsx(T, { ns: "notifications", keyName: channelsMapping[i].toLowerCase() }) }, i)); }) })) }), _jsx("div", { className: "flex-1 mr-4", children: _jsx(TextField, __assign({ className: "w-full", label: tolgee.t({ key: "email", ns: "generic" }) }, register("email"))) })] }) }), _jsx("div", { className: "mt-8", children: _jsx(Button, { onClick: handleSubmit(onSubmit), children: _jsx(T, { ns: "generic", keyName: "add" }) }) })] }));
}
