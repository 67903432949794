var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { BaseIconButton, SearchField, SplitButton } from "components";
import { api } from "services";
import { getDSRUrl } from "helpers/case";
import CaseRow from "pages/General/Dsr/CaseRow";
import { useAppDispatch, useAppSelector } from "store";
import { useDebounce } from "helpers/hooks";
import { dataGridConfig } from "helpers/dataGridConfig";
import tolgee from "services/translation";
import { getDaysLeft } from "helpers/date";
import { changePreference } from "store/app";
export default function DsrList() {
    var _this = this;
    var _a = useAppSelector(function (state) { return state.app.preferences; }), _b = _a.createTestCaseIndex, createTestCaseIndex = _b === void 0 ? 0 : _b, _c = _a.caseRows, caseRows = _c === void 0 ? 25 : _c, _d = _a.caseSortIndex, caseSortIndex = _d === void 0 ? 0 : _d;
    var dispatch = useAppDispatch();
    var _e = useState(""), search = _e[0], setSearch = _e[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _f = useState(), data = _f[0], setData = _f[1];
    var _g = useState(0), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var _j = useState(false), dsrLoading = _j[0], setDsrLoading = _j[1];
    var businessId = useParams().businessId;
    var managedBusinesses = useAppSelector(function (state) { return state.user.managed_businesses; });
    var _k = useState("created_at"), sortBy = _k[0], setSortBy = _k[1];
    var _l = useState("desc"), orderedBy = _l[0], setOrderedBy = _l[1];
    var _m = useState(true), caseActive = _m[0], setCaseActive = _m[1];
    var _o = useState([]), items = _o[0], setItems = _o[1];
    useEffect(function () {
        document.title = businessId ? "DSR Inbox" : "Global DSR Inbox";
    }, [businessId]);
    useEffect(function () {
        sortBtnItems[caseSortIndex].action();
    }, [caseSortIndex]);
    var sortBtnItems = [
        {
            name: "First Created",
            details: "DS request with closest creation date",
            action: function () {
                setSortBy("created_at");
                setOrderedBy("desc");
            },
            icon: "arrow-down-short-wide",
        },
        {
            name: "Last Created",
            details: "DS request with latest creation date",
            action: function () {
                setSortBy("created_at");
                setOrderedBy("asc");
            },
            icon: "arrow-down-wide-short",
        },
        {
            name: "Last Updated",
            details: "DS request with oldest updates",
            action: function () {
                setSortBy("updated_at");
                setOrderedBy("asc");
            },
            icon: "arrow-down-wide-short",
        },
        {
            name: "Nearest Deadline",
            details: "DS nearest with closest deadline",
            action: function () {
                setSortBy("deadline");
                setOrderedBy("asc");
            },
            icon: "arrow-down-wide-short",
        },
        {
            name: "Furthest Deadline",
            details: "DS furthest with closest deadline",
            action: function () {
                setSortBy("deadline");
                setOrderedBy("desc");
            },
            icon: "arrow-down-short-wide",
        },
    ];
    var createTestCase = function (caseType, article) { return __awaiter(_this, void 0, void 0, function () {
        var dsr;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!businessId) return [3 /*break*/, 2];
                    setDsrLoading(true);
                    return [4 /*yield*/, api.createTestCase(businessId, caseType, article)];
                case 1:
                    dsr = _a.sent();
                    setDsrLoading(false);
                    window.location.assign(getDSRUrl(dsr.type, dsr.business.public_id, dsr.uuid));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        function setDSRButton(activeProducts) {
            var tempItems = [];
            if (activeProducts.some(function (p) { return p === "art27"; })) {
                tempItems.push({
                    name: tolgee.t({ key: "create.gdpr_15", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_LEGACY_EU", 15); },
                }, {
                    name: tolgee.t({ key: "create.gdpr_17", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_LEGACY_EU", 17); },
                });
            }
            if (activeProducts.some(function (p) { return p === "ukrep"; })) {
                tempItems.push({
                    name: tolgee.t({ key: "create.uk_gdpr_15", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_LEGACY_UK", 15); },
                }, {
                    name: tolgee.t({ key: "create.uk_gdpr_17", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_LEGACY_UK", 17); },
                });
            }
            if (activeProducts.some(function (p) { return p === "swissrep"; })) {
                tempItems.push({
                    name: tolgee.t({ key: "create.fadp_deletion", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_FADP_DELETE", null); },
                }, {
                    name: tolgee.t({ key: "create.fadp_access", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_FADP_ACCESS", null); },
                }, {
                    name: tolgee.t({ key: "create.fadp_correction", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_FADP_CORRECT", null); },
                }, {
                    name: tolgee.t({ key: "create.fadp_automated_decision", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_FADP_AUTOMATED_DECISION", null); },
                }, {
                    name: tolgee.t({ key: "create.fadp_prohibit_data_processing", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_FADP_PROHIBIT_DATA_PROCESSING", null); },
                });
            }
            if (activeProducts.some(function (p) { return p === "ccpa"; })) {
                tempItems.push({
                    name: tolgee.t({ key: "create.ccpa_deletion", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_DELETE", null); },
                }, {
                    name: tolgee.t({ key: "create.ccpa_access", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_ACCESS", null); },
                }, {
                    name: tolgee.t({ key: "create.ccpa_access_shared", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_ACCESS_SHARED", null); },
                }, {
                    name: tolgee.t({ key: "create.ccpa_correction", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_CORRECT", null); },
                }, {
                    name: tolgee.t({ key: "create.ccpa_opt_out", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_OPTOUT", null); },
                }, {
                    name: tolgee.t({ key: "create.ccpa_limit", ns: "dsr" }),
                    action: function () { return createTestCase("DSR_CCPA_LIMIT", null); },
                });
            }
            setItems(tempItems);
        }
        if (businessId) {
            var managedBusiness = managedBusinesses.find(function (mb) { return mb.public_id === businessId; });
            if (managedBusiness && Array.isArray(managedBusiness.features)) {
                setDSRButton(managedBusiness.features);
            }
        }
    }, [businessId, managedBusinesses]);
    var fetchData = function (props) { return __awaiter(_this, void 0, void 0, function () {
        var order_by, page, per_page, order, search_term, desc, res, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    order_by = props.order_by, page = props.page, per_page = props.per_page, order = props.order, search_term = props.search_term;
                    desc = order === "desc";
                    return [4 /*yield*/, api.getCases(businessId, "DSR", caseActive, order_by, page + 1, per_page, desc, search_term)];
                case 1:
                    res = _b.sent();
                    if (!res) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: caseRows, count: res.count })), { onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            dispatch(changePreference({ caseRows: val.pageSize }));
                        }, columns: [
                            {
                                flex: 1,
                                field: "",
                                renderCell: function (params, idx) {
                                    return _jsx(CaseRow, { case_: params.row }, idx);
                                },
                            },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }), onRowClick: function (params) {
                            var dsr = params.row;
                            window.location.assign(getDSRUrl(dsr.type, dsr.business_public_id, dsr.uuid));
                        } });
                    setData(tableData);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchData({
            order_by: sortBy,
            page: currentPage,
            per_page: caseRows,
            order: orderedBy,
            search_term: debouncedSearchValue,
        });
    }, [businessId, currentPage, caseRows, sortBy, orderedBy, debouncedSearchValue, caseActive]);
    var handleSelectedRequestIndex = function (index) {
        dispatch(changePreference({ createTestCaseIndex: index }));
    };
    var handleSelectedSortIndex = function (index) {
        dispatch(changePreference({ caseSortIndex: index }));
    };
    return (_jsxs("div", { className: "box-outerlayout !block mx-auto max-w-7xl", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center space-x-2", children: [_jsx(BaseIconButton, { className: "border-full", onClick: function () {
                                    return fetchData({
                                        order_by: sortBy,
                                        page: currentPage,
                                        per_page: caseRows,
                                        order: orderedBy,
                                        search_term: debouncedSearchValue,
                                    });
                                }, children: _jsx("div", { className: "flex items-center justify-center w-4 h-4", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }) }), _jsx("h1", { children: _jsx(T, { ns: "billing", keyName: "data_subject_requests" }) })] }), _jsxs("div", { className: "flex space-x-2", children: [_jsx(SplitButton, { items: sortBtnItems, listHeight: "424px", state: [caseSortIndex, handleSelectedSortIndex] }), businessId && items.length > 0 && (_jsx(SplitButton, { listHeight: "500px", items: items, state: [createTestCaseIndex, handleSelectedRequestIndex], disabled: dsrLoading, loading: dsrLoading, buttonPreName: "Create Test Request: " })), _jsxs(ToggleButtonGroup, { className: "h-10", value: caseActive, exclusive: true, onChange: function (_, newState) {
                                    if (newState !== null && newState !== undefined) {
                                        setCaseActive(newState);
                                    }
                                }, children: [_jsx(ToggleButton, { value: true, children: _jsx(T, { keyName: "active", ns: "cases" }) }), _jsx(ToggleButton, { value: false, children: _jsx(T, { keyName: "closed", ns: "cases" }) })] })] })] }), data ? (_jsxs("div", { children: [_jsx(SearchField, { className: "w-full", searchState: [search, setSearch] }), _jsx(DataGrid, __assign({ getRowClassName: function (params) {
                            var showDanger = !(params.row.state === "CLOSED") &&
                                (params.row.deadline_warning || getDaysLeft(params.row.deadline) <= 0);
                            return showDanger ? "bg-danger/10" : "";
                        }, sx: {
                            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                        }, slots: {
                            columnHeaders: function () { return null; },
                        } }, data, { loading: loading }))] })) : (!loading && _jsx("div", { children: tolgee.t({ key: "no_data", ns: "billing" }) }))] }));
}
