var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { BaseButton } from "components/Button";
import DataCategories from "components/DSRSettings/CCPA/DataCategories";
import { shareDisclosedData } from "const/ccpa";
import tolgee from "services/translation";
export default function SharingData(_a) {
    var isEnabled = _a.isEnabled, shareData = _a.shareData, saveSharingData = _a.saveSharingData;
    var _b = useState(), dataOptions = _b[0], setDataOptions = _b[1];
    var _c = useState({
        isEnabled: false,
        items: [],
    }), selectedOptions = _c[0], setSelectedOptions = _c[1];
    useEffect(function () {
        saveSharingData(selectedOptions);
        if (selectedOptions.items.length === 0 && selectedOptions.isEnabled) {
            addCategory();
        }
    }, [selectedOptions]);
    useEffect(function () {
        setSelectedOptions(function (prevData) { return (__assign(__assign({}, prevData), { isEnabled: isEnabled })); });
    }, [isEnabled]);
    useEffect(function () {
        var formattedData = shareDisclosedData.map(function (item) { return ({
            dataToSell: {
                key: item.dataToSell,
                value: tolgee.t({ key: "".concat(item.dataToSell) }),
            },
            sellOptions: item.sellOptions.map(function (sellOptionItem) { return ({
                key: sellOptionItem,
                value: tolgee.t({ key: "".concat(sellOptionItem) }),
            }); }),
            selected: false,
        }); });
        setDataOptions(formattedData);
        if (shareData) {
            var items_1 = [];
            Object.keys(shareData).forEach(function (key) {
                var formattedItem = formattedData.find(function (item) { return item.dataToSell.key === key; });
                var selectedItem = {
                    dataToSell: formattedItem.dataToSell,
                    sellOptions: formattedItem.sellOptions,
                    sellTo: shareData[key].map(function (val) {
                        return formattedItem.sellOptions.find(function (option) { return option.key === val; });
                    }),
                };
                items_1.push(selectedItem);
                var newData = __spreadArray([], formattedData, true);
                newData.find(function (item) { return item.dataToSell.key === selectedItem.dataToSell.key; }).selected = true;
                setDataOptions(newData);
            });
            setSelectedOptions(function (prevData) { return (__assign(__assign({}, prevData), { items: items_1 })); });
        }
    }, [shareData]);
    var addCategory = function () {
        var item = {
            dataToSell: {
                key: "",
                value: "",
            },
            sellOptions: [],
            sellTo: [],
        };
        var updatedData = __assign(__assign({}, selectedOptions), { items: __spreadArray(__spreadArray([], selectedOptions.items, true), [item], false) });
        setSelectedOptions(updatedData);
    };
    var handleSwitch = function (value) {
        if (value) {
            setSelectedOptions(function (prevData) { return (__assign(__assign({}, prevData), { isEnabled: value })); });
        }
        else {
            setSelectedOptions({
                isEnabled: value,
                items: [],
            });
        }
    };
    var handleCategory = function (selectedItem, index, value) {
        var localItem = selectedItem;
        var newData = __spreadArray([], dataOptions, true);
        if (localItem.dataToSell.value !== "") {
            newData.find(function (item) { return item.dataToSell.value === localItem.dataToSell.value; }).selected = false;
        }
        newData.find(function (item) { return item.dataToSell.value === value; }).selected = true;
        setDataOptions(newData);
        if (localItem.dataToSell.value !== value) {
            localItem = {
                dataToSell: {
                    key: dataOptions.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                },
                sellTo: [],
                sellOptions: dataOptions.filter(function (item) { return item.dataToSell.value === value; })[0].sellOptions,
            };
        }
        else {
            localItem = __assign(__assign({}, selectedItem), { dataToSell: {
                    key: dataOptions.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                }, sellOptions: dataOptions.filter(function (item) { return item.dataToSell.value === value; })[0].sellOptions });
        }
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], selectedOptions.items.slice(0, index), true), [
            localItem
        ], false), selectedOptions.items.slice(index + 1), true);
        var updatedData = __assign(__assign({}, selectedOptions), { items: updatedItems });
        setSelectedOptions(updatedData);
    };
    var handleOption = function (selectedItem, index, value) {
        var item = __assign(__assign({}, selectedItem), { sellTo: value.map(function (val) {
                return selectedItem.sellOptions.find(function (option) { return option.value === val; });
            }) });
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], selectedOptions.items.slice(0, index), true), [
            item
        ], false), selectedOptions.items.slice(index + 1), true);
        var updatedData = __assign(__assign({}, selectedOptions), { items: updatedItems });
        setSelectedOptions(updatedData);
    };
    var getCategoryData = function (selectedItem) {
        var value = dataOptions.filter(function (item) { return !item.selected || item.dataToSell.value === selectedItem.dataToSell.value; });
        return value.map(function (item) { return item.dataToSell.value; });
    };
    return (_jsxs("div", { className: "mt-4 mb-4 box-dotted", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.sharing_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.sharing_description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: selectedOptions.isEnabled, onChange: function (event) { return handleSwitch(event.target.checked); } }), label: selectedOptions.isEnabled ? (_jsx(T, { keyName: "dsr.settings.ccpa.sharing_switch_active" })) : (_jsx(T, { keyName: "dsr.settings.ccpa.sharing_switch_disabled" })) }) }), selectedOptions.isEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: selectedOptions.items.map(function (item, index) { return (_jsx("div", { className: "mb-2", children: _jsx(DataCategories, { categoryValue: item.dataToSell.value, categoryData: getCategoryData(item), categoryOptionsValue: item.sellTo.map(function (sellToItem) { return sellToItem.value; }), categoryOptionsData: item.sellOptions.map(function (sellOptionItem) { return sellOptionItem.value; }), handleCategorySelect: function (value) { return handleCategory(item, index, value); }, handleOptionSelect: function (value) { return handleOption(item, index, value); } }) }, index)); }) }), dataOptions.length > selectedOptions.items.length ? (_jsx("div", { className: "mb-4 w-full flex align-center justify-center", children: _jsx(BaseButton, { className: "w-auto", variant: "outlined", onClick: addCategory, children: _jsx(T, { keyName: "dsr.settings.ccpa.sharing_add_category" }) }) })) : (""), _jsx("div", { className: "font-thin italic", children: _jsx(T, { keyName: "dsr.settings.ccpa.sharing_note" }) })] })) : ("")] }));
}
