var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { T } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { api } from "services";
import { useAppDispatch, useAppSelector } from "store";
import ToDoList from "pages/Client/Dashboard/Components/ToDoList";
import StatCard from "pages/Client/Dashboard/Components/StatCard";
import ProductList from "pages/Client/Dashboard/Components/ProductList";
import { emptyDashboardData, getFinishedToDoCount, getTotalToDoCount, isThereUnfinishedToDo, } from "pages/Client/Dashboard/dashboardHelpers";
import tolgee from "services/translation";
import { fetchUserData } from "store/thunks";
import { isManager } from "helpers/general";
export default function ViewDashboard() {
    var _a;
    var _b = useAppSelector(function (state) { return state.user; }), managed_businesses = _b.managed_businesses, roles = _b.roles;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _c = useState(emptyDashboardData), dashboardData = _c[0], setDashboardData = _c[1];
    var _d = useState(true), showDashboard = _d[0], setShowDashboard = _d[1];
    var _e = useState(false), allToDosDone = _e[0], setAllToDosDone = _e[1];
    useEffect(function () {
        if (isManager(roles)) {
            navigate("/manage-users");
        }
    }, [roles, navigate]);
    useEffect(function () {
        function getDashboardData() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.getDashboardData()];
                        case 1:
                            res = _a.sent();
                            if (!res.user_todos) {
                                return [2 /*return*/];
                            }
                            setAllToDosDone(!isThereUnfinishedToDo(res));
                            setDashboardData(res);
                            return [2 /*return*/];
                    }
                });
            });
        }
        getDashboardData();
        document.title = tolgee.t({ key: "dashboard", ns: "dashboard" });
        dispatch(fetchUserData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getBusiness = function (businessId) {
        return managed_businesses.find(function (b) { return b.public_id === businessId; });
    };
    var getProductStatus = function (businessId) {
        return dashboardData.product_status[businessId];
    };
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "dashboard-page", children: [!allToDosDone && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between pb-2 mt-2", children: [_jsxs("span", { className: "text-lg text-brand-900", children: [_jsx(T, { keyName: "company_profile_data", ns: "dashboard" }), ":", _jsxs("span", { className: clsx({
                                            "text-success-400": getFinishedToDoCount(dashboardData) === getTotalToDoCount(dashboardData),
                                        }, "text-orange-400"), children: [getFinishedToDoCount(dashboardData), "/", getTotalToDoCount(dashboardData)] })] }), _jsx(Switch, { checked: showDashboard, onChange: function () { return setShowDashboard(!showDashboard); }, inputProps: { "aria-label": "controlled" } })] }), showDashboard && (_jsx(ToDoList, { userToDos: (_a = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.user_todos) === null || _a === void 0 ? void 0 : _a.filter(function (t) { return !t.todo; }), businessTodos: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.business_todos }))] })), Object.entries(dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.stats).map(function (_a) {
                var _b, _c, _d, _e, _f;
                var businessId = _a[0], statVal = _a[1];
                return (_jsxs("div", { className: "box-outerlayout mb-4 mt-4", children: [_jsxs("div", { className: "flex flex-col md:flex-row justify-between", children: [_jsxs("div", { className: "flex flex-col items-start", children: [_jsxs("div", { className: "flex flex-col md:flex-row items-center text-center py-4", children: [_jsx("h2", { className: "m-0 mr-2 text-2xl", children: ((_b = getBusiness(businessId)) === null || _b === void 0 ? void 0 : _b.company_name) || businessId }), _jsxs("span", { className: "self-center text-gray-600 font-semibold", children: ["(", _jsx(T, { keyName: "id", ns: "generic" }), ": ", businessId, ")"] })] }), _jsx(StatCard, { stats: statVal })] }), ((_d = (_c = getBusiness(businessId)) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.logo) && (_jsx("figure", { className: "mt-4 image", children: _jsx("img", { alt: "company logo", style: { maxHeight: "96px", maxWidth: "96px" }, src: (_f = (_e = getBusiness(businessId)) === null || _e === void 0 ? void 0 : _e.config) === null || _f === void 0 ? void 0 : _f.logo }) }))] }), _jsx(ProductList, { activeProducts: getProductStatus(businessId), businessId: businessId })] }, businessId));
            })] }));
}
