var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import NotFound from "pages/General/Error/NotFound";
import { fetchCaseData } from "store/thunks";
import AuthorityView from "pages/Client/Case/Authority/View";
import DSRView from "pages/Client/Case/DSR/ClientView/View";
import providerState from "pages/Client/Case/CaseContext/initialState";
import providerReducer from "pages/Client/Case/CaseContext/reducer";
import { DispatchContext, StateContext } from "pages/Client/Case/CaseContext";
export default function CaseView() {
    var caseUuid = useParams().caseUuid;
    var _a = useReducer(providerReducer, __assign(__assign({}, providerState), { case: __assign(__assign({}, providerState.case), { uuid: caseUuid }) })), state = _a[0], dispatch = _a[1];
    useEffect(function () {
        fetchCaseData()(dispatch, state.case.uuid);
    }, []);
    var getCaseComponent = function () {
        if (state.initialLoading) {
            return _jsx(DSRView, {});
        }
        if (!state.initialLoading && (!state.case || !state.case.type)) {
            return _jsx(NotFound, {});
        }
        if (state.case.type === "AUTHORITY_INBOX") {
            return (_jsx(AuthorityView, { caseData: state.case, handleRefresh: function () { return fetchCaseData()(dispatch, state.case.uuid); } }));
        }
        if (state.case.type.includes("FADP") || state.case.type.includes("CCPA")) {
            return _jsx(DSRView, {});
        }
        return _jsx(NotFound, {});
    };
    return (_jsxs(StateContext.Provider, { value: state, children: [_jsx(DispatchContext.Provider, { value: dispatch, children: getCaseComponent() }), state.loading && _jsx("div", { className: "absolute top-0 left-0 w-full h-full min-h-screen z-10" })] }));
}
