import { Tolgee, BackendFetch, DevTools, FormatSimple } from "@tolgee/react";

import { TOLGEE_CDN, TOLGEE_KEY, TOLGEE_URL } from "const/env";

const activatedNSs = [
  "nav",
  "product_name",
  "email_intake",
  "settings",
  "generic",
  "billing",
  "cases",
  "contacts",
  "ops",
  "ccpa",
  "dsr",
  "report",
  "marketing",
  "support",
  "legal",
  "nis",
  "fadp",
  "notifications",
  "ropa",
  "permissions",
  "referrals",
  "onboarding",
  "swissrep",
  "gdpr",
  "all_lang",
  "rep",
  "public_dsr",
  "dashboard",
  "partner",
  "partners",
  "clp",
  "users",
  "loa",
  "churned",
  "public_clp",
  "vouchers",
  "error",
];

function getTolgee() {
  const tolgee = Tolgee()
    .use(BackendFetch({ prefix: TOLGEE_CDN }))
    .use(DevTools())
    .use(FormatSimple())
    .init({
      language: "en",
      fallbackLanguage: "en",
      apiUrl: TOLGEE_URL,
      apiKey: TOLGEE_KEY,
    });
  activatedNSs.forEach((ns) => tolgee.addActiveNs(ns));

  return tolgee;
}

export default getTolgee();
