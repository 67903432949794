var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";
import tolgee from "services/translation";
export default function AdditionalInfoForm(_a) {
    var _b, _c;
    var data = _a.data, additionalInfoState = _a.additionalInfoState;
    var dsrs = data.dsrs, actors = data.actors;
    var additionalInfo = additionalInfoState[0], setAdditionalInfo = additionalInfoState[1];
    var _d = useFormContext(), register = _d.register, watch = _d.watch, setValue = _d.setValue;
    var privacy_related = watch("request_privacy_related");
    var identifiable_info = watch("identifiable_info");
    var files = watch("files");
    var getTypesList = function () {
        var dsrsTypes = [];
        dsrs === null || dsrs === void 0 ? void 0 : dsrs.filter(function (i) { return i.checked === true; }).forEach(function (i) {
            if (i.type === "DSR_CCPA_ACCESS") {
                return i.inquiries.forEach(function (j) {
                    if (j.checked) {
                        dsrsTypes.push(j.type);
                    }
                });
            }
            return dsrsTypes.push(i.type);
        });
        return dsrsTypes === null || dsrsTypes === void 0 ? void 0 : dsrsTypes.map(function (j) { return (_jsxs("div", { children: ["- ", _jsx(T, { keyName: "type.".concat(j.toLowerCase()), ns: "dsr" })] }, j)); });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("h2", { children: [_jsx(T, { keyName: "summary", ns: "ccpa" }), ":"] }), _jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "request_type", ns: "ccpa" }) }), getTypesList()] }), _jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "data_subject_info", ns: "ccpa" }) }), _jsxs("div", { children: [_jsx(T, { keyName: "name", ns: "ccpa" }), ": ", actors[0].name] }), _jsxs("div", { children: [_jsx(T, { keyName: "email", ns: "ccpa" }), ": ", identifiable_info.email] })] }), ((_b = actors[1]) === null || _b === void 0 ? void 0 : _b.acting_for) !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "requester_info", ns: "ccpa" }) }), ((_c = actors[1]) === null || _c === void 0 ? void 0 : _c.acting_for) && (_jsxs("div", { children: [_jsx(T, { keyName: "acting_for", ns: "ccpa" }), ":", " ", _jsx(T, { keyName: "ds_info.acting_for.".concat(actors[1].acting_for.toLowerCase()), ns: "ccpa" })] })), _jsxs("div", { children: [_jsx(T, { keyName: "name", ns: "ccpa" }), ": ", actors[1].name] }), _jsxs("div", { children: [_jsx(T, { keyName: "email", ns: "ccpa" }), ": ", actors[1].email] })] })), files.length > 0 && (_jsxs("div", { children: [_jsx("h4", { children: _jsx(T, { keyName: "uploads", ns: "ccpa" }) }), files.map(function (i) { return (_jsxs("div", { children: ["- ", i.name] }, i.name)); })] })), _jsxs("div", { className: "mt-8", children: [_jsxs("b", { children: [_jsx(T, { keyName: "email.for_contact", ns: "dsr" }), ":"] }), " ", actors[1].email] }), _jsxs(_Fragment, { children: [_jsxs("h2", { className: "mt-8", children: [_jsx(T, { keyName: "additional_data", ns: "ccpa" }), ":"] }), _jsx(TextField, { onChange: function (e) { return setAdditionalInfo(e.target.value); }, value: additionalInfo, className: "w-full", label: tolgee.t({ key: "additional_data.additional_info", ns: "ccpa" }), rows: 5, multiline: true, InputLabelProps: {
                            shrink: true,
                        } })] }), _jsx("button", { className: "mt-2", type: "button", onClick: function () { return setValue("request_privacy_related", !privacy_related); }, children: _jsxs("div", { className: "flex items-center text-center", children: [_jsx(Checkbox, __assign({}, register("request_privacy_related"), { checked: privacy_related })), _jsxs("span", { className: "font-bold", children: [_jsx(T, { keyName: "additional_data.request_privacy_related", ns: "ccpa" }), " *"] })] }) })] }));
}
