var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { T, useTranslate } from "@tolgee/react";
import { BaseSelectField, ExtendedSelectField } from "components";
import { getCountryAsOptions } from "helpers";
import IdentificationCheck from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWho/IdentificationCheck";
import { inputStyle, selectStyle } from "pages/Client/EmailCases/Pages/styling";
import { useAppSelector } from "store";
function WhoForm(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var setStep = props.setStep;
    var _j = useFormContext(), watch = _j.watch, setValue = _j.setValue, register = _j.register, getValues = _j.getValues, errors = _j.formState.errors;
    var t = useTranslate("email_intake").t;
    var _k = useState([]), countryOptions = _k[0], setCountryOptions = _k[1];
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    useEffect(function () {
        if (countries) {
            var options = getCountryAsOptions(countries);
            setCountryOptions(options);
        }
    }, [countries]);
    var countryIso = watch("identifiable_info.address.country_iso");
    var acting_for = watch("actors.1.acting_for");
    return (_jsxs("div", { className: "flex flex-col gap-4  items-start text-darkgray ", children: [_jsxs("div", { className: "flex flex-col gap-2 w-full", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "data_subject_is_for", ns: "email_intake" }) }), _jsx(BaseSelectField, { error: !!((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.acting_for), required: true, sx: selectStyle, state: [acting_for, function (val) { return setValue("actors.1.acting_for", val); }], 
                        // label={tolgee.t({ key: "ds_info.acting_for", ns: "fadp" })}
                        options: {
                            MYSELF: t("option_for_himself"),
                            AS_PARENT: t("option_as_parent"),
                            AS_LEGAL_REPRESENTATIVE: t("option_as_legal_representative"),
                        } })] }), !!acting_for && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray w-full", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "name", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_c = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _c === void 0 ? void 0 : _c.username) }, register("identifiable_info.username", {
                                        required: t("name_is_required"),
                                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "name", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_d = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _d === void 0 ? void 0 : _d.email) }, register("identifiable_info.email", {
                                        validate: function (value) {
                                            var actingFor = getValues("actors.1.acting_for");
                                            if (actingFor === "MYSELF" && !value) {
                                                return t("email_is_required");
                                            }
                                            return true;
                                        },
                                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "customer_id", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.customer_number")))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "phone_number", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.phone")))] })] }), _jsxs("div", { className: "grid grid-cols-2 md:grid-cols-4 gap-4  items-start text-darkgray ", children: [_jsxs("div", { className: "flex flex-col gap-2 col-span-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "city", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.city")))] }), _jsxs("div", { className: "flex flex-col gap-2 col-span-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "street", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.street")))] }), _jsxs("div", { className: "flex flex-col gap-2 ", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "postal", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.postal")))] }), _jsxs("div", { className: "flex flex-col gap-2 col-span-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "country", ns: "generic" }) }), _jsx(ExtendedSelectField, { sx: inputStyle, state: [
                                            countryIso,
                                            function (text) { return setValue("identifiable_info.address.country_iso", text); },
                                        ], options: countryOptions, label: "" })] })] }), acting_for !== "MYSELF" && (_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray w-full mt-6", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "name", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_f = (_e = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.name) }, register("actors.1.name", {
                                        validate: function (value) {
                                            var actingFor = getValues("actors.1.acting_for");
                                            if (actingFor !== "MYSELF" && !value) {
                                                return t("name_is_required");
                                            }
                                            return true;
                                        },
                                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "email", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_h = (_g = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _g === void 0 ? void 0 : _g[1]) === null || _h === void 0 ? void 0 : _h.email) }, register("actors.1.email", {
                                        validate: function (value) {
                                            var actingFor = getValues("actors.1.acting_for");
                                            if (actingFor !== "MYSELF" && !value) {
                                                return t("email_is_required");
                                            }
                                            return true;
                                        },
                                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "phone_number", ns: "generic" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("actors.1.phone")))] })] })), _jsx(IdentificationCheck, { setStep: function () { return setStep(1); } })] }))] }));
}
export default WhoForm;
